import React from "react";
import "./ErrorMessage.scss";

function ErrorMessage(props) {
  const { errorCode } = props;

  function getErrorMessage() {
    console.log("Error logged: ", errorCode);
    switch (errorCode) {
      case "anonymous-auth-failed":
        return "Anonymous authentication failed. Try again.";
      case "cookies-not-enabled":
        return "This app requires cookies to be enabled, please enable in your browser and reload.";
      case "login-token-not-valid":
        return "Failed to retrieve login token. Try again - if problem persists, please contact us.";
      case "add-list-item-error":
        return "Failed to add grocery item to list. Try again.";
      case "create-list-error":
        return "Failed to create the grocery list. Try again.";
      case "add-user-to-list-error":
        return "Failed to add user to the grocery list. Try again.";
      case "grocery-item-desc-req":
        return "grocery item description required";
      case "duplicate-item-error":
        return "grocery item on list already";
      case "user-name-required":
        return "your name is required";
      case "grocery-list-item-get-fail":
        return "failed to get grocery list items";
      default:
        return "Oops, something went wrong.";
    }
  }

  return errorCode ? <p className="error">{getErrorMessage()}</p> : null;
}

export default ErrorMessage;
