import React, { useEffect } from "react";
import { Card, Banner, Stack, Button, Spinner, CalloutCard, DataTable } from "@shopify/polaris";
import { useState } from "react";
import ErrorMessage from "../components/ErrorMessage";
import { getPlans, selectPlan } from "../services/account";
import { Plan } from "../model/Plan";
import { useAppBridge } from "@shopify/app-bridge-react";
import moment from 'moment';
function Account(props) {
  const { current, scheduledRulesObservable } = props;
  const [plans, setPlans] = useState<Plan[]>([]);
  const [ruleCounts, setRuleCounts] = useState({ general: 0, specific: 0, optIn: 0 });
  const [comparePlans, setComparePlans] = useState(false);
  const [executing, setExecuting] = useState<boolean>(false);
  const [error] = useState<string | null>();
  const app = useAppBridge();

  useEffect(() => {
    setPlans(getPlans());
  }, []);

  useEffect(() => {
    if (scheduledRulesObservable) {
      const rules = scheduledRulesObservable.docs.map((doc) => doc.data());
      setRuleCounts({
        general: (rules.filter((rule) => rule.who.targeting === "general") || []).length,
        specific: (rules.filter((rule) => rule.who.targeting === "specific") || []).length,
        optIn: (rules.filter((rule) => rule.who.targeting === "optin") || []).length,
      });
    }
  }, [scheduledRulesObservable]);

  const activatePlan = (key) => {
    selectPlan(key, app);
    setExecuting(true);
  };

  const getRowsForComparison = () => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
    const showUnlimited = (val) => {
      return val === -1 ? "Unlimited" : val;
    };
    return plans.map((plan) => {
      return [
        plan.name,
        showUnlimited(plan.quota.total),
        showUnlimited(plan.quota.general),
        plan.quota.specific,
        plan.quota.additional,
        plan.key === "free" ? "FREE" : formatter.format(plan.price / 100) + "/m",
      ];
    });
  };

  return (
    <Stack vertical>
      {error ? <ErrorMessage errorCode={error}></ErrorMessage> : ""}
      {current ? (
        <CalloutCard
          title="Current plan"
          illustration="/assets/Account.png"
          primaryAction={{
            content: `Compare plans`,
            onAction: () => setComparePlans(!comparePlans),
          }}
        >
          <Banner key="banner">You are currently on {current.plan.key === "free" ? "the FREE" : current.plan.name} plan.</Banner>
          <p>
            This month you have logged {current.usage.usage.specific || 0} specific events with {ruleCounts.specific} currently scheduled.
          </p>
          <p>You currently have {ruleCounts.general + ruleCounts.optIn} general or opt-in rules active.</p>
          <p>Your quota will reset in {moment(current.usage.resetDate).diff(moment(), 'days')} days</p>
        </CalloutCard>
      ) : (
        ""
      )}
      {comparePlans ? (
        <DataTable
          columnContentTypes={["text", "numeric", "numeric", "numeric", "numeric"]}
          headings={["Plan", "Total events*", "General/Opt-in rules", "Specific rule events*", "Additional events**", "Price"]}
          rows={getRowsForComparison()}
          footerContent={"*Events per month, **Additional specific events charged if quota exceeded"}
        />
      ) : (
        ""
      )}
      {plans.map((plan) => {
        return (
          <Card key={plan.key} sectioned title={plan.name}>
            {executing ? (
              <Spinner />
            ) : (
              <div>
                <p>{plan.description}</p>
                <ul>
                  {plan.features.map((f) => {
                    return <li key={f.key}>{f.name}</li>;
                  })}
                </ul>
                <p>{plan.additional || ""}</p>
                <h2>{plan.price === 0 ? "Free" : `$${(plan.price / 100).toFixed(0)}/month`}</h2>
                <br />
                <Button disabled={current && current.plan.key === plan.key} onClick={() => activatePlan(plan.key)}>
                  {current && current.plan.key === plan.key ? "Current Plan" : "Select Plan"}
                </Button>
              </div>
            )}
          </Card>
        );
      })}
    </Stack>
  );
}

export default Account;
