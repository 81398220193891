import React, { useState, useEffect } from "react";
import { Button, ResourceList, Avatar, ResourceItem, TextStyle, Stack, TextField, Spinner, Label } from "@shopify/polaris";
import { searchProducts } from "../services/api";

const ProductSearchField = ({ product, selectProduct, selectedLabel, searchLabel }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searching, setSearching] = useState<boolean | null>(null);
  const [searchResults, setSearchResults] = useState<any[]>();
  const [productId, setProductId] = useState<any>("");

  useEffect(() => {
    if (searchTerm.length > 1) {
      setSearching(true);
      const fetchProducts = async () => {
        const results = await searchProducts(searchTerm);
        setSearchResults(results);
        setSearching(false);
      };
      fetchProducts();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (productId && !product) {
      selectProduct(
        searchResults?.find((product) => {
          return product.id === productId;
        })
      );
    }
  }, [productId, selectProduct, searchResults, product]);

  const reset = () => {
    setSearchTerm("");
    setSearchResults([]);
    setProductId("");
    selectProduct(null);
  };

  return (
    <Stack vertical>
      {product != null ? (
        <>
          <Label id="title">{selectedLabel}</Label>
          <ResourceItem
            onClick={() => {}}
            id={`${product.id}`}
            url={product.url}
            media={<Avatar source={product.image} size="medium" name={product.name} />}
            accessibilityLabel={`View details for ${product.name}`}
          >
            <Stack alignment="center">
              <Stack.Item fill>
              <h3>
                <TextStyle variation="strong">{product.name}</TextStyle>
              </h3>
              </Stack.Item>
              <Stack.Item>
                <Button
                  destructive
                  outline
                  size="slim"
                  onClick={() => reset()}
                >X</Button>
              </Stack.Item>
            </Stack>
          </ResourceItem>
        </>
      ) : (
        <>
          <TextField autoComplete="off" label={searchLabel} value={searchTerm} onChange={setSearchTerm}></TextField>
          {searching === true ? <div className="spinner-holder"><Spinner /></div> : ""}
          {searching === false && searchResults?.length ? (
            <ResourceList
              resourceName={{ singular: "product", plural: "products" }}
              items={searchResults}
              renderItem={(item) => {
                const { id, image, name, url } = item;
                const media = <Avatar source={image} size="medium" name={name} />;

                return (
                  <ResourceItem onClick={setProductId} id={`${id}`} url={url} media={media} accessibilityLabel={`View details for ${name}`}>
                    <h3>
                      <TextStyle variation="strong">{name}</TextStyle>
                    </h3>
                  </ResourceItem>
                );
              }}
            />
          ) : (
            ""
          )}
        </>
      )}
    </Stack>
  );
};
export { ProductSearchField };
