import {useCallback, useEffect, useState} from 'react';

const useCopyToClipboard = (resetInterval = 0) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleIsCopiedChange = useCallback(value => setIsCopied(value), []);

    const copyToClipboard = (text: string): void => {
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        handleIsCopiedChange(true);
    }

    useEffect(() => {
        let timeout;
        if (isCopied && resetInterval > 0) {
            timeout = setTimeout(() => handleIsCopiedChange(false), resetInterval);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [isCopied, handleIsCopiedChange, 
        resetInterval]);

    return { isCopied, copyToClipboard };
}

export default useCopyToClipboard