import React, { useEffect } from "react";
import { disconnect } from "../services/firebase";
import { useHistory } from "react-router-dom";
import { Spinner } from '@shopify/polaris';

function Start(props) {
  const history = useHistory();
  useEffect(() => {
    props.requestLogin();
    history.push(`${process.env.REACT_APP_HOME_ROUTE}${window.location.search}`);
    return () => {
      disconnect();
    };
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  return <Spinner></Spinner>;
}

export default Start;
