import React, { useState, useEffect, useCallback } from "react";
import { ChoiceList, Stack, TextField } from "@shopify/polaris";
import { WhatConstants } from "./WhatTypes";

function TagActionForm({ setConfig, initial }) {
  const [tag, setTag] = useState("");
  const [type, setType] = useState("order");

  const TAG_TYPES = [
    { label: "Order", value: "order" },
    { label: "Customer", value: "customer" },
  ];

  const handleTagChange = useCallback((value) => setTag(value), []);
  const handleTypeChange = useCallback((value) => setType(value[0]), []);

  useEffect(() => {
    if (initial && initial.what.config) {
      setTag(initial.what.config.tag);
      if (initial.what.config.type != null) {
        setType(initial.what.config.type)
      }
    }
  }, [initial]);

  useEffect(() => {
    setConfig({
      tag,
      type,
    });
  }, [tag, type, setConfig]);

  return (
    <Stack vertical>
      <ChoiceList title="What to tag" choices={TAG_TYPES} selected={[type]} onChange={handleTypeChange} />
      <TextField autoComplete="off" label={WhatConstants.TAG_LABEL} value={tag} onChange={handleTagChange}></TextField>
    </Stack>
  );
}
export default TagActionForm;
