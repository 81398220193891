import React, { useState, useCallback, useEffect } from "react";
import { Layout, Card, Stack, ChoiceList } from "@shopify/polaris";
import TagActionForm from "./TagActionForm";
import { DiscountActionForm } from "./DiscountActionForm";
import { EmailActionForm } from "./EmailActionForm";
import { ProductActionForm } from "./ProductActionForm";
import { WhatConstants, WhatTypes } from "./WhatTypes";
import FlowActionForm from './FlowActionForm';

const WhatForm = ({ what, setWhat, initial }) => {
  const [action, setAction] = useState("tag");
  const [config, setConfig] = useState("");

  const handleChange = useCallback((value) => setAction(value[0]), []);

  useEffect(() => {
    if (initial && initial.what) {
      setAction(initial.what.action);
      setConfig(initial.what.config);
    }
  }, [initial]);

  useEffect(() => {
    setWhat({
      action,
      config,
    });
  }, [action, config, setWhat]);

  return (
    <Layout.AnnotatedSection
      title="Action"
      description={
        <Stack>
          <p>
            When a customer who matches your targeting conditions places an order, choose the action you would like to perform - either
            applying a tag to the Order or Customer, or changing line item details.
          </p>
        </Stack>
      }
    >
      <Card sectioned>
        <Stack vertical>
          <ChoiceList title={WhatConstants.ACTION_TITLE} choices={WhatConstants.ACTION_TYPES} selected={[action]} onChange={handleChange} />
          {action === WhatTypes.TAG_ORDER_VALUE ? <TagActionForm {...{ setConfig, initial }} /> : ""}
          {action === WhatTypes.DISCOUNT_VALUE ? <DiscountActionForm {...{ setConfig, initial }} /> : ""}
          {action === WhatTypes.EMAIL_VALUE ? <EmailActionForm {...{ setConfig, initial }} /> : ""}
          {action === WhatTypes.PRODUCT_VALUE ? <ProductActionForm {...{ setConfig, initial }} /> : ""}
          {action === WhatTypes.FLOW_TRIGGER_VALUE ? <FlowActionForm {...{ setConfig, initial }} /> : ""}
        </Stack>
      </Card>
    </Layout.AnnotatedSection>
  );
};
export default WhatForm;
