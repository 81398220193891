interface TargetingRule {
  subject: string;
  action: string;
  value: string;
}

export const WhoTargeting = {
  TITLE: "Rule targeting",
  SPECIFIC_LABEL: "One (or more) individual customers place an order",
  SPECIFIC_VALUE: "specific",
  GENERAL_LABEL: "A group of customers place an order",
  GENERAL_VALUE: "general",
  OPT_IN_LABEL: "A customer clicks a URL",
  OPT_IN_VALUE: "optin",
  FLOW_ACTION_LABEL: "Receive trigger from Shopify Flow™",
  FLOW_ACTION_VALUE: "flow_action",
};

export const WhoConstants = {
  getWhoTypes: (rulesExceeded) => [
    {
      label: WhoTargeting.SPECIFIC_LABEL,
      value: WhoTargeting.SPECIFIC_VALUE,
      helpText: "Build a target list of individual existing customers to target this rule against"
    },
    {
      label: WhoTargeting.GENERAL_LABEL,
      value: WhoTargeting.GENERAL_VALUE,
      helpText: "Configure targeting rules to apply to groups of customers",
      disabled: rulesExceeded,
    },
    {
      label: WhoTargeting.OPT_IN_LABEL,
      value: WhoTargeting.OPT_IN_VALUE,
      helpText: "Generate a link that customers can click to trigger this rule",
      disabled: rulesExceeded,
    },
    // {
    //   label: WhoTargeting.FLOW_ACTION_LABEL,
    //   value: WhoTargeting.FLOW_ACTION_VALUE,
    //   helpText: "Trigger this rule from a Shopify Flow™ Action",
    //   disabled: rulesExceeded,
    // },
  ],
  WHO_INITIAL: { config: { customers: [] }, targeting: WhoTargeting.SPECIFIC_VALUE },
};

export const RuleSubjectsValues = {
  ORDER_LABEL: "Order Tags",
  ORDER_TEXT: "Order",
  ORDER_TAGS: "order_tags",
  ORDER_NUMBER_LABEL: "Order Number",
  ORDER_NUMBER_TEXT: "Order",
  ORDER_NUMBER: "order_number",
  CUSTOMER_LABEL: "Customer Tags",
  CUSTOMER_TEXT: "Customer",
  CUSTOMER_TAGS: "customer_tags",
  ORDER_LINE_LABEL: "Order Line Item",
  ORDER_LINE_TEXT: "Item",
  ORDER_LINE_ITEM: "order_line_item",
  ORDER_VALUE_LABEL: "Order Value",
  ORDER_VALUE: "order_value",
  SHIPPING_ADDRESS_LABEL: "Shipping Address",
  SHIPPING_ADDRESS: "shipping_address",
  BILLING_ADDRESS_LABEL: "Billing Address",
  BILLING_ADDRESS: "billing_address",
  TAGGED_WITH: "tagged with",
  NOT_TAGGED_WITH: "not tagged with",
  CONTAINS_SKU: "contains SKU",
  ONLY_CONTAINS_SKU: "only contains SKU",
  NOT_CONTAINS_SKU: "does not contain SKU",
  N_A: "N/A",
};

export const RuleActionsValues = {
  CONTAINS_SKU_LABEL: "Contains SKU",
  CONTAINS_SKU: "contains_sku",
  ONLY_CONTAINS_SKU_LABEL: "Only contains SKU",
  ONLY_CONTAINS_SKU: "only_sku",
  NOT_CONTAINS_SKU_LABEL: "Does not contain SKU",
  NOT_CONTAINS_SKU: "not_contains_sku",
  TAGGED_WITH: "Does have tag",
  DOES: "does",
  NOT_TAGGED_WITH: "Doesn't have tag",
  DOES_NOT: "does_not",
  COUNTRY_LABEL: "Country equals",
  COUNTRY: "country",
  STATE_LABEL: "State equals",
  STATE: "state",
  CITY_LABEL: "City equals",
  CITY: "city",
  SUBURB_LABEL: "Suburb equals",
  SUBURB: "suburb",
  ZIP_LABEL: "Zip equals",
  ZIP: "zip",
  FIRST_LABEL: "Is first order",
  FIRST: "first",
  EQUALS_LABEL: "Equals",
  EQUALS: "equals",
  GTE_LABEL: "Greater or Equal to",
  GTE: "gte",
  LTE_LABEL: "Less or Equal to",
  LTE: "lte",
  GT_LABEL: "Greater than",
  GT: "gt",
  LT_LABEL: "Less than",
  LT: "lt",
  NOT_EQUALS_LABEL: "Does not Equals",
  NOT_EQUALS: "not_equals",
  MULTIPLE_LABEL: "Is multiple of",
  MULTIPLE: "multiple",
};

export const TargetingRuleConstants = {
  INITIAL_RULE: { subject: RuleSubjectsValues.ORDER_TAGS, action: RuleActionsValues.DOES, value: "" },
  INITIAL_OR: [],
  RULE_SUBJECTS: [
    { label: RuleSubjectsValues.ORDER_LABEL, value: RuleSubjectsValues.ORDER_TAGS },
    { label: RuleSubjectsValues.ORDER_NUMBER_LABEL, value: RuleSubjectsValues.ORDER_NUMBER },
    { label: RuleSubjectsValues.CUSTOMER_LABEL, value: RuleSubjectsValues.CUSTOMER_TAGS },
    { label: RuleSubjectsValues.ORDER_VALUE_LABEL, value: RuleSubjectsValues.ORDER_VALUE },
    { label: RuleSubjectsValues.ORDER_LINE_LABEL, value: RuleSubjectsValues.ORDER_LINE_ITEM },
    { label: RuleSubjectsValues.SHIPPING_ADDRESS_LABEL, value: RuleSubjectsValues.SHIPPING_ADDRESS },
    { label: RuleSubjectsValues.BILLING_ADDRESS_LABEL, value: RuleSubjectsValues.BILLING_ADDRESS },
  ],
  ACTION_ORDER_TAGS: [
    { label: RuleActionsValues.TAGGED_WITH, value: RuleActionsValues.DOES },
    { label: RuleActionsValues.NOT_TAGGED_WITH, value: RuleActionsValues.DOES_NOT },
  ],
  ACTION_CUSTOMER_TAGS: [
    { label: RuleActionsValues.TAGGED_WITH, value: RuleActionsValues.DOES },
    { label: RuleActionsValues.NOT_TAGGED_WITH, value: RuleActionsValues.DOES_NOT },
  ],
  ACTION_ORDER_LINE_ITEMS: [
    { label: RuleActionsValues.CONTAINS_SKU_LABEL, value: RuleActionsValues.CONTAINS_SKU },
    { label: RuleActionsValues.ONLY_CONTAINS_SKU_LABEL, value: RuleActionsValues.ONLY_CONTAINS_SKU },
    { label: RuleActionsValues.NOT_CONTAINS_SKU_LABEL, value: RuleActionsValues.NOT_CONTAINS_SKU },
  ],
  ACTION_ORDER_NUMBER: [
    { label: RuleActionsValues.FIRST_LABEL, value: RuleActionsValues.FIRST },
    { label: RuleActionsValues.EQUALS_LABEL, value: RuleActionsValues.EQUALS },
    { label: RuleActionsValues.NOT_EQUALS_LABEL, value: RuleActionsValues.NOT_EQUALS },
    { label: RuleActionsValues.GT_LABEL, value: RuleActionsValues.GT },
    { label: RuleActionsValues.LT_LABEL, value: RuleActionsValues.LT },
    { label: RuleActionsValues.MULTIPLE_LABEL, value: RuleActionsValues.MULTIPLE },
  ],
  ACTION_ORDER_VALUE: [
    { label: RuleActionsValues.EQUALS_LABEL, value: RuleActionsValues.EQUALS },
    { label: RuleActionsValues.GTE_LABEL, value: RuleActionsValues.GTE },
    { label: RuleActionsValues.LTE_LABEL, value: RuleActionsValues.LTE },
  ],
  ACTION_LOCATION: [
    { label: RuleActionsValues.COUNTRY_LABEL, value: RuleActionsValues.COUNTRY },
    { label: RuleActionsValues.STATE_LABEL, value: RuleActionsValues.STATE },
    { label: RuleActionsValues.CITY_LABEL, value: RuleActionsValues.CITY },
    { label: RuleActionsValues.SUBURB_LABEL, value: RuleActionsValues.SUBURB },
    { label: RuleActionsValues.ZIP_LABEL, value: RuleActionsValues.ZIP },
  ],
  NO_ACTION: [],
};
