import React, { useState, useCallback, useEffect } from "react";
import { Stack, TextField, Select } from "@shopify/polaris";
import { WhatConstants } from "./WhatTypes";

function DiscountActionForm({ setConfig, initial }) {
  const [discountType, setDiscountType] = useState("percent");
  const [discount, setDiscount] = useState("10");

  const handleDiscountTypeChange = useCallback((value) => setDiscountType(value), [setDiscountType]);
  const handleDiscountChange = useCallback((value) => setDiscount(value), [setDiscount]);

  useEffect(() => {
    if (initial && initial.what.config) {
      handleDiscountTypeChange(initial.what.config.discountType);
      handleDiscountChange(initial.what.config.discount);
    }
  }, [initial, handleDiscountTypeChange, handleDiscountChange]);

  useEffect(() => {
    setConfig({
      discountType,
      discount,
    });
  }, [discountType, discount, setConfig]);

  const discountTypes = WhatConstants.DISCOUNT_TYPES;

  return (
    <Stack>
      <Select label={WhatConstants.DISCOUNT_TYPES_LABEL} options={discountTypes} value={discountType} onChange={handleDiscountTypeChange} />
      <TextField autoComplete="off" label={WhatConstants.DISCOUNT_AMOUNT_LABEL} value={discount} onChange={handleDiscountChange}></TextField>
    </Stack>
  );
}
export { DiscountActionForm };
