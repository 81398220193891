import React from "react";
import { Card, Heading, Layout, Image } from "@shopify/polaris";

function Help() {
  return (
    <Layout>
      <Layout.Section>
        <Layout.AnnotatedSection>
          <Card sectioned>Find instructions below on how to configure, manage and scheduled rules.</Card>
        </Layout.AnnotatedSection>
      </Layout.Section>
      <Layout.Section>
        <Layout.AnnotatedSection
          title="Trigger"
          description="The Trigger configuration of a rule defines what will cause the rule to be executed. Rules can either be targeted for specific individuals, a group of customers based on rules, or via an opt in URL."
        >
          <Card sectioned>
            <p>
              The Trigger part of a rule defines who the rule will be triggered or applied to. This can be specific individuals, a set of
              general rules to segment a group or to generate a rule that can be shared via email or clicked on your website for someone to
              opt-in.
            </p>
            <br />
            <Heading>Specific</Heading>
            <br />
            <p>To target one or more individual customers, use the search box to search by first name, last name or email address.</p>
            <Image style={{ width: "80%" }} source="/assets/help/help-who-specific-1.png" alt="Specific search"></Image>
            <p>Once you have found the right customer, click the entry to add the customer to the list of targeted customers.</p>
            <p>Targeted customers can be removed with the delete button.</p>
            <Image style={{ width: "80%" }} source="/assets/help/help-who-specific-2.png" alt="Specific targeted"></Image>
            <br />
            <Heading>Generic</Heading>
            <br />
            <p>Create one or more conditions to target a group of your customers for the rule to be applied to.</p>
            <p>
              Rules can be created a OR or AND rules, OR rules will match any rule whereas AND rules will need to satisfy all conditions
              before triggering the rule.
            </p>
            <p>Once a rule has been added, select the required subject, action and if applicable the value</p>
            <Image style={{ width: "80%" }} source="/assets/help/help-who-general-1.png" alt="General targeted"></Image>
            <p>General targeting requires at least one condition to be added.</p>
            <p>Conditions can be removed with the remove and remove all buttons.</p>
            <br />
            <Heading>Opt-in</Heading>
            <br />
            <p>
              The final type of targeting that can be created is an opt-in email, these emails can be sent via email or placed on your
              website to be clicked to opt-in to a rule.
            </p>
            <Image style={{ width: "80%" }} source="/assets/help/help-who-opt-in-1.png" alt="Opt in targeted"></Image>
            <p>
              Configure the URLs (either a relative or absolute URL) that the customer should be redirected to after a successful or failed
              opt-in click.
            </p>
          </Card>
        </Layout.AnnotatedSection>
      </Layout.Section>
      <Layout.Section>
        <Layout.AnnotatedSection
          title="Action"
          description="The Action section configuration controls what happens when a rule executes. ie, the action it will take."
        >
          <Card sectioned>
            <Heading>Apply a tag</Heading>
            <br />
            <p>Configure to add a customer or order tag when the targeted customer places an order.</p>
            <Image style={{ width: "80%" }} source="/assets/help/help-what-tag-1.png" alt="Apply a tag"></Image>
            <p>Enter the tag value to be added to the customer or order.</p>
            <Heading>Modify a line item</Heading>
            <br />
            <p>With this option you can add, remove or update the quantity of a line item within an order.</p>
            <p>First enter the SKU code of the variant you wish to look for in an order</p>
            <p>Then select the operation you wish to perform;</p>
            <ul>
              <li>Replace the product with another product.</li>
              <li>Modify the quantity of the product the customer will receive.</li>
              <li>Remove the line item from the order.</li>
            </ul>
            <Image style={{ width: "80%" }} source="/assets/help/help-what-product-1.png" alt="Modify a line item"></Image>
            <p>If the selected operation is "Replace" then provide the SKU code of the new product to add.</p>
            <p>
              If the selected operation is "Replace" or "Modify the quantity" then select how the new line item quantity will be calculated.
              Either match the original line items quantity or set the item to a fixed quantity.
            </p>
            <p>Finally select how to deal with instances where there is a difference in price after the rule has been applied.</p>
          </Card>
        </Layout.AnnotatedSection>
      </Layout.Section>
      <Layout.Section>
        <Layout.AnnotatedSection
          title="Limits"
          description="The Limits configuration defines when the rule is available and how many times it can be executed."
        >
          <Card sectioned>
            <br />
            <p>Select how many times the rule can be executed, once, multiple or an unlimited number of times.</p>
            <p>Then select if the restrictions apply per customer or in total.</p>
            <Image style={{ width: "80%" }} source="/assets/help/help-when-fixed-1.png" alt="Apply a tag"></Image>
            <p></p>
            <Heading>Modify a line item</Heading>
            <br />
            <p></p>
            <Image style={{ width: "80%" }} source="/assets/help/help-what-product-1.png" alt="Modify a line item"></Image>
          </Card>
        </Layout.AnnotatedSection>
      </Layout.Section>
      <Layout.Section>
        <Layout.AnnotatedSection title="Scheduled" description="View a list of scheduled rules.">
          <Card sectioned>
            <p>The scheduled screen displays all active rules configured. Click on a rule to modify the configuration.</p>
            <Image style={{ width: "80%" }} source="/assets/help/help-scheduled-1.png" alt="Scheduled rules"></Image>
          </Card>
        </Layout.AnnotatedSection>
      </Layout.Section>
      <Layout.Section>
        <Layout.AnnotatedSection title="Log" description="View a list of executed rules.">
          <Card sectioned>
            <p>
              Every time a rule executes on a customer an entry is displayed in the log, you can view individual executed rules in this
              screen.
            </p>
            <Image style={{ width: "80%" }} source="/assets/help/help-log-1.png" alt="Executed rules"></Image>
          </Card>
        </Layout.AnnotatedSection>
      </Layout.Section>
    </Layout>
  );
}

export default Help;
