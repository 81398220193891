import React, { useState, useEffect } from "react";
import { Stack, Pagination, Card, IndexTable, Link } from "@shopify/polaris";
import { buildExecutedRuleRenderItem } from "../services/labels";
import { getNextLog, getPreviousLog, getShop } from "../services/firebase";
import { useHistory } from "react-router-dom";

const ExecutedLog = ({ executedRulesObservable }) => {
  const [executedRules, setExecutedRules] = useState<any[]>([]);
  const [docs, setDocs] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  useEffect(() => {
    if (executedRulesObservable && executedRulesObservable.docs) setDocs(executedRulesObservable.docs.map((doc) => doc));
  }, [executedRulesObservable]);

  useEffect(() => {
    setExecutedRules(docs.map((item, index) => buildExecutedRuleRenderItem(item.data(), index)));
  }, [docs]);

  const nextRules = async () => {
    if (docs.length === 20) {
      const start = docs[docs.length - 1];
      setDocs((await getNextLog(start)).docs.map((item) => item));
      setCurrentPage(currentPage + 1);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const previousRules = async () => {
    if (currentPage !== 1) {
      const end = docs[0];
      setDocs((await getPreviousLog(end)).docs.map((item) => item));
      setCurrentPage(currentPage - 1);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const loadRule = (id) => {
    history.push(`/rule/${id}${window.location.search}`);
  };

  const rowMarkup = executedRules.map((data, index) => {
    const { id, date, what, ruleId, rule, customerId, firstName, lastName, entityId } = data;

    return (
      <IndexTable.Row id={id} key={id} position={index}>
        <IndexTable.Cell>{date.toLocaleString()}</IndexTable.Cell>
        <IndexTable.Cell>
          <button
            className="Polaris-Link"
            onClick={(e) => {
              e.preventDefault();
              loadRule(ruleId);
            }}
          >
            {rule?.label || what}
          </button>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <a
            className="Polaris-Link"
            href={`https://${getShop()}/admin/customers/${customerId}`}
            target="_top"
          >{`${firstName} ${lastName}`}</a>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {customerId !== entityId && (
            <a className="Polaris-Link" href={`https://${getShop()}/admin/orders/${entityId}`} target="_top">
              {entityId}
            </a>
          )}
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  return (
    <Card sectioned>
      <IndexTable
        selectable={false}
        itemCount={executedRules.length}
        headings={[{ title: "Time Executed" }, { title: "Rule" }, { title: "Customer" }, { title: "Order" }]}
      >
        {rowMarkup}
      </IndexTable>
      {executedRules.length ? (
        <Stack distribution="center">
          <Pagination
            label=" "
            hasPrevious={currentPage > 1}
            onPrevious={previousRules}
            hasNext={executedRules.length === 20}
            onNext={nextRules}
          />
        </Stack>
      ) : (
        ""
      )}
    </Card>
  );
};

export default ExecutedLog;
