import React, { useState, useEffect, useCallback } from "react";
import { CalloutCard, Checkbox, ChoiceList, Stack, TextField, Tooltip } from "@shopify/polaris";

import { optInUrlLabel } from "../../../services/labels";
import useCopyToClipboard from "../../../services/CopyToClipboard";

function OptInForm({ config, setConfig, ruleId }) {
  const [successUrl, setSuccessUrl] = useState<string>("");
  const [failedUrl, setFailedUrl] = useState<string>("");
  const [emailLookup, setEmailLookup] = useState(false);
  const [type, setType] = useState("order");
  const { isCopied, copyToClipboard } = useCopyToClipboard(3000);

  const LINK_TYPES = [
    { label: "Order", value: "order" },
    { label: "Customer", value: "customer", helpText: "Customer links cannot modify the contents or tags of an order" },
  ];

  const setConfigCallback = useCallback(
    () => setConfig({ successUrl, failedUrl, type, emailLookup }),
    [successUrl, failedUrl, type, emailLookup, setConfig]
  );
  const handleEmailLookupChange = useCallback((value) => setEmailLookup(value), []);

  const setInitialCallback = useCallback(() => {
    if (config.successUrl) {
      setSuccessUrl(config.successUrl);
    }
    if (config.failedUrl) {
      setFailedUrl(config.failedUrl);
    }
    if (config.type) {
      setType(config.type);
    }
    setEmailLookup(config.emailLookup === true);
  }, [config]);
  const handleTypeChange = useCallback((value) => setType(value[0]), []);

  useEffect(
    () => {
      setInitialCallback();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setConfigCallback();
  }, [successUrl, failedUrl, type, setConfigCallback]);

  return (
    <Stack spacing="tight" vertical>
      <ChoiceList
        title="What type of entity will this link be bound to"
        choices={LINK_TYPES}
        selected={[type]}
        onChange={handleTypeChange}
      />
      {type === "customer" ? (
        <Checkbox
          label="Look up customer from email address"
          checked={emailLookup}
          onChange={handleEmailLookupChange}
          helpText="This creates a guessable URL, only use for non critical tasks"
        />
      ) : (
        <></>
      )}
      <TextField autoComplete="off" label="URL to redirect to after opt in success" type="url" value={successUrl} onChange={setSuccessUrl} />
      <TextField autoComplete="off" label="URL to redirect to after opt in failure" type="url" value={failedUrl} onChange={setFailedUrl} helpText="Optional" />
      {ruleId && ruleId.length ? (
        <CalloutCard
          title="Share this URL to trigger this rule"
          illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
          primaryAction={{
            content: "Copy link to clipboard",
            onAction: () => copyToClipboard(optInUrlLabel({ id: ruleId, who: { config: { type, emailLookup } } })),
          }}
        >
          <p>
            {isCopied ? (
              <Tooltip active content="Copied!">
                <a target="_blank" rel="noopener noreferrer" href={optInUrlLabel({ id: ruleId, who: { config: { type, emailLookup } } })}>
                  {optInUrlLabel({ id: ruleId, who: { config: { type, emailLookup } } })}
                </a>
              </Tooltip>
            ) : (
              <a target="_blank" rel="noopener noreferrer" href={optInUrlLabel({ id: ruleId, who: { config: { type, emailLookup } } })}>
                {optInUrlLabel({ id: ruleId, who: { config: { type, emailLookup } } })}
              </a>
            )}
          </p>
          <p>Note you'll need to replace {`"{{${type === "order" ? "order.id" : emailLookup === true ? "email" : "customer.id"}}}"`} with the actual id</p>
        </CalloutCard>
      ) : (
        ""
      )}
    </Stack>
  );
}
export { OptInForm };
