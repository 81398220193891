import { plans } from "../model/Plan";
import { getPlanAndUsage, setPlan } from "./api";
import { Redirect } from "@shopify/app-bridge/actions";

const getPlans = () => {
  return plans;
};

const getCurrentPlan = async (): Promise<any> => {
  const usage = await getPlanAndUsage();
  return { usage, plan: plans.find((c) => c.key === usage.key) };
};

const selectPlan = async (key, app) => {
  const current = await getCurrentPlan();
  if (current && current.plan.key !== key) {
    const redirect = Redirect.create(app);
    const url = await setPlan(key);
    if (url === "free") {
      await redirect.dispatch(Redirect.Action.APP, "/");
    } else {
      await redirect.dispatch(Redirect.Action.ADMIN_PATH, url.split("/admin")[1]);
    }
    return;
  }
};

export { getPlans, getCurrentPlan, selectPlan };
