import React from "react";
import { CalloutCard } from "@shopify/polaris";


const RuleQuota = (props) => {
  const { rulesExceeded } = props;

  return (
    <>
      {" "}
      {rulesExceeded ? (
        <CalloutCard
          title="Over Account limit"
          illustration="/assets/Account.png"
          primaryAction={{
            content: `Upgrade plan`,
            url: "/account"
            //   onAction: () => setComparePlans(!comparePlans),
          }}
        >
          <p>You have used up your quota of General or Opt-in rules for your account type. Upgrade your account to add more rules.</p>
        </CalloutCard>
      ) : (
        ""
      )}
    </>
  );
};
export default RuleQuota;
