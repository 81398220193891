import React, { useState, useEffect, useCallback } from "react";
import { Layout, Card, Stack, ChoiceList } from "@shopify/polaris";
import { GeneralTargetingForm } from "./GeneralTargetingForm";
import { SpecificTargetingForm } from "./SpecificTargetingForm";
import { OptInForm } from "./OptInForm";
import { WhoConstants, WhoTargeting } from "./WhoTypes";

function WhoForm({ who, setWho, ruleId, initial, rulesExceeded }) {
  const [config, setConfig] = useState(who ? who.config : "");
  const [targeting, setTargeting] = useState(who ? who.targeting : WhoTargeting.SPECIFIC_VALUE);

  const handleTargetingChange = useCallback((value) => {
    setTargeting(value[0]);
  }, []);

  useEffect(() => {
    if (initial && initial.who) {
      setTargeting(initial.who.targeting);
      setConfig(initial.who.config);
    }
  }, [initial]);

  useEffect(() => {
    setWho({
      targeting,
      config,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targeting, config]);

  return (
    <Layout.AnnotatedSection
      title="Trigger"
      description={
        <Stack>
          <p>
            Choose whether you want to target specific individual customers, apply the rule to a group of customers or create an opt-in link.
            <br></br>
            <br></br>
            Make sure you read our <a href="/help">Help</a> information about how to use AND OR rules if you want to create complex targeted groups.
          </p>
        </Stack>
      }
    >
      <Card sectioned>
        <Stack spacing="tight" vertical>
          return (
          <ChoiceList
            title={WhoTargeting.TITLE}
            titleHidden
            choices={WhoConstants.getWhoTypes(rulesExceeded)}
            selected={[targeting]}
            onChange={handleTargetingChange}
          />
          );
          {targeting === WhoTargeting.SPECIFIC_VALUE ? <SpecificTargetingForm {...{ config, setConfig }} /> : ""}
          {targeting === WhoTargeting.GENERAL_VALUE ? <GeneralTargetingForm {...{ config, setConfig }} /> : ""}
          {targeting === WhoTargeting.OPT_IN_VALUE ? <OptInForm {...{ config, setConfig, ruleId }} /> : ""}
        </Stack>
      </Card>
    </Layout.AnnotatedSection>
  );
}
export default WhoForm;
