import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Stack, Pagination, Card, OptionList, Filters, IndexTable} from "@shopify/polaris";
import ErrorMessage from "../components/ErrorMessage";
import { buildScheduledRuleRenderItem } from "../services/labels";
import { getNextScheduledRule, getPreviousScheduledRule } from "../services/firebase";
import { searchCustomers } from "../services/api";
import { useDebounce } from 'use-debounce';
import { ruleMap, triggerMap } from "../constants/global";

function ScheduledRules({ searchScheduledRulesObservable, customerSearchResults, setCustomerSearchResults, targetingSearch, setTargetingSearch }) {
  const [scheduledRules, setScheduledRules] = useState<any[]>([]);
  const [docs, setDocs] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error] = useState<string | null>();
  const history = useHistory();
  const [searchKey, setSearchKey] = useState('');
  const [debounceValue] = useDebounce(searchKey, 1000);

  const loadRule = (id) => {
    history.push(`/rule/${id}${window.location.search}`);
  };

  useEffect(() => {
    if (searchScheduledRulesObservable && searchScheduledRulesObservable.docs) setDocs(searchScheduledRulesObservable.docs.map((doc) => doc))
    else {
      if (customerSearchResults || targetingSearch) {
        setDocs([])
      }
    }
  }, [searchScheduledRulesObservable, customerSearchResults, targetingSearch]);

  useEffect(() => {
    setScheduledRules(docs.map((item) => buildScheduledRuleRenderItem(item.data())));
  }, [docs]);

  useEffect(() => {
    if (debounceValue) {
      searchCustomers(debounceValue)
        .then((results) => {
          setCustomerSearchResults(results)
        })
    } else {
      setCustomerSearchResults(null)
    }
  }, [debounceValue, setCustomerSearchResults])

  const nextRules = async () => {
    if (docs.length === 20) {
      const start = docs[docs.length - 1];
      setDocs((await getNextScheduledRule(start)).docs.map((item) => item));
      setCurrentPage(currentPage + 1);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  };

  const previousRules = async () => {
    if (currentPage !== 1) {
      const end = docs[0];
      setDocs((await getPreviousScheduledRule(end)).docs.map((item) => item));
      setCurrentPage(currentPage - 1);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  };

  const onSearchKeyChange = async (value) => {
    setSearchKey(value)
  }

  const handleSelectChange = useCallback((value) => {
    setTargetingSearch(value)
  }, [setTargetingSearch]);

  const options = [
    {label: 'All rules', value: 'any'},
    {label: 'Specific', value: 'specific'},
    {label: 'General', value: 'general'},
    {label: 'Opt-in', value: 'optin'},
  ];

  const filters = [
    {
      key: 'targeting',
      label: 'Targeting',
      filter: (
        <OptionList
          title="Targeting"
          options={options}
          selected={targetingSearch || []}
          onChange={handleSelectChange}
        />
      ),
      shortcut: true,
    },
  ];

  const handleSearchKeyRemove = useCallback(() => onSearchKeyChange(null), []);

  const handleFiltersClearAll = useCallback(() => {
    handleSearchKeyRemove();
    setTargetingSearch('any')
  }, [
    handleSearchKeyRemove,
    setTargetingSearch
  ]);

  const appliedFilters: any[] = [];

  if (targetingSearch !== 'any') {
    appliedFilters.push({
      key: targetingSearch,
      label: targetingSearch,
      onRemove: () => {
        setTargetingSearch('any')
      }
    })
  }

  const rowMarkup = scheduledRules.map(
    ({id, targeting, what, label}, index) => (
      <IndexTable.Row
        id={id}
        key={id}
        position={index}
      >
        <IndexTable.Cell>
          {triggerMap[targeting]}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <button
            className="Polaris-Link"
            onClick={(e) => {
              e.preventDefault();
              loadRule(id);
            }}
          >
            {label || what}
          </button>
        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  return (
    <Card sectioned>
      <ErrorMessage errorCode={error}></ErrorMessage>

      <Filters
        queryValue={searchKey}
        queryPlaceholder="Filter rules"
        filters={filters}
        appliedFilters={appliedFilters}
        onQueryChange={onSearchKeyChange}
        onQueryClear={handleSearchKeyRemove}
        onClearAll={handleFiltersClearAll}
      />
      
      <IndexTable
        selectable={false}
        itemCount={scheduledRules.length}
        headings={[
          {title: 'Trigger'},
          {title: 'Rules'}
        ]}
      >
        {rowMarkup}
      </IndexTable>

      {scheduledRules.length ? (
        <Stack distribution="center">
          <Pagination
            label=" "
            hasPrevious={currentPage > 1}
            onPrevious={previousRules}
            hasNext={scheduledRules.length === 20}
            onNext={nextRules}
          />
        </Stack>
      ) : (
        ""
      )}
    </Card>
  );
}

export default ScheduledRules;
