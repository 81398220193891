import React, { useState, useEffect, useCallback } from "react";
import { ResourceList, Avatar, ResourceItem, TextStyle, Stack, TextField, Spinner, Label, Button } from "@shopify/polaris";
import { searchCustomers } from "../services/api";

function CustomerSearchField(props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searching, setSearching] = useState<boolean | null>(null);
  const [searchResults, setSearchResults] = useState<any[]>();
  const [customerId, setCustomerId] = useState<any>("");
  const { customers, selectCustomer, removeCustomer } = props;

  const handleSearchTermsChange = useCallback((value) => setSearchTerm(value), []);

  const reset = useCallback(() => {
    handleSearchTermsChange("");
    setSearchResults([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchTerm.length > 3) {
      setSearching(true);
      const fetchCustomers = async () => {
        let results = (await searchCustomers(searchTerm)).filter((result) => !customers.some((customer) => customer.id === result.id));
        setSearchResults(results);
        setSearching(false);
      };
      fetchCustomers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (customerId && searchResults?.length) {
      selectCustomer(
        searchResults?.find((customer) => {
          return customer.id === customerId;
        })
      );
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  return (
    <Stack vertical>
      {customers && customers.length ? (
        <>
          <Label id="title">{props.selectedLabel}</Label>
          <ResourceList
            resourceName={{ singular: "customer", plural: "customers" }}
            items={customers}
            selectable={false}
            renderItem={(customer: any) => {
              return (
                <ResourceItem
                  onClick={() => {}}
                  id={`${customer.id}`}
                  media={<Avatar source={customer.image} size="medium" name={customer.displayName} />}
                  accessibilityLabel={`View details for ${customer.name}`}
                >
                  <Stack alignment="center">
                    <Stack.Item fill>
                      <h3>
                        <TextStyle variation="strong">
                          {customer.firstName} {customer.lastName}
                        </TextStyle>
                      </h3>
                      <TextStyle>
                        Id: {customer.id}<br />Email: {customer.email}
                      </TextStyle>
                    </Stack.Item>
                    <Stack.Item>
                      <Button
                        destructive
                        outline
                        size="slim"
                        onClick={() => {
                          reset();
                          removeCustomer(customer.id);
                        }}
                      >
                        X
                      </Button>
                    </Stack.Item>
                  </Stack>
                </ResourceItem>
              );
            }}
          />
        </>
      ) : (
          ""
        )}
      <>
        <TextField autoComplete="off" label={props.searchLabel} value={searchTerm} onChange={handleSearchTermsChange}></TextField>
        {searching === true ? <div className="spinner-holder"><Spinner /></div> : ""}
        {searching === false && searchResults?.length ? (
          <ResourceList
            resourceName={{ singular: "customer", plural: "customers" }}
            items={searchResults}
            renderItem={(item) => {
              return (
                <ResourceItem
                  onClick={setCustomerId}
                  id={`${item.id}`}
                  media={<Avatar size="medium" name={`${item.displayName}`} />}
                  accessibilityLabel={`View details for ${item.displayName}`}
                >
                  <h3>
                    <TextStyle variation="strong">
                      {item.displayName}
                    </TextStyle>
                  </h3>
                  <TextStyle>
                    Email: {item.email}
                  </TextStyle>
                </ResourceItem>
              );
            }}
          />
        ) : searching === false ? (
          <p>No results found, please try a different name or email address.</p>
        ) : (
          ""
        )}
      </>
    </Stack>
  );
}
export { CustomerSearchField };
