import React, { useState, useEffect, useCallback } from "react";
import { Page, AppProvider, Stack, PageActions } from "@shopify/polaris";
import { StoreMajor, MobilePlusMajor, ListMajor, ChecklistAlternateMajor } from '@shopify/polaris-icons';
import { Provider } from "@shopify/app-bridge-react";
import { getShopOrigin } from "./services/api";
import Account from "./scenes/Account";
import Start from "./scenes/Start";
import Help from "./scenes/Help";
import Privacy from "./scenes/Privacy";
import ExecutedLog from "./scenes/ExecutedLog";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import RuleDetail from "./scenes/RuleDetail";
import ScheduledRules from "./scenes/ScheduledRules";

import "./App.scss";
import { getCurrentPlan } from "./services/account";
import { onAuthStateChange, authenticate, getScheduledRulesObserver, getLogRulesObserver, searchScheduledRulesObserver } from "./services/firebase";

function App() {
  const [current, setCurrent] = useState<any>(null);
  const [user, setUser] = useState({ loggedIn: false });
  const [authError, setAuthError] = useState("");
  const [executedRulesObservable, setExecutedRulesObservable] = useState();
  const [scheduledRulesObservable, setScheduledRulesObservable] = useState();
  const [searchScheduledRulesObservable, setSearchScheduledRulesObservable] = useState();
  const [customerSearchResults, setCustomerSearchResults] = useState<any[] | null>(null)
  const [targetingSearch, setTargetingSearch] = useState(['any'])

  const config = {
    apiKey: `${process.env.REACT_APP_API_KEY}`,
    shopOrigin: getShopOrigin(),
    forceRedirect:false
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser);
    return () => {
      unsubscribe();
    };
  }, []);

  const requestLogin = useCallback(() => {
    authenticate().catch((error) => setAuthError(error.code));
  }, []);

  useEffect(() => {
    if (user.loggedIn) {
      getCurrentPlan().then(setCurrent);
    }
  }, [user]);

  useEffect(() => {
    if (user.loggedIn) {
      getScheduledRulesObserver(setScheduledRulesObservable);
      getLogRulesObserver(setExecutedRulesObservable);
    }
  }, [user]);

  useEffect(() => {
    if (user.loggedIn) {
      searchScheduledRulesObserver(setSearchScheduledRulesObservable, { customers: customerSearchResults, targeting: targetingSearch[0] === 'any' ? '' : targetingSearch[0] });
      getLogRulesObserver(setExecutedRulesObservable);
    }
  }, [user, customerSearchResults, targetingSearch])

  return (
    <AppProvider
      i18n={{
        Polaris: {
          ResourceList: {
            sortingLabel: "Sort by",
            defaultItemSingular: "item",
            defaultItemPlural: "items",
            Item: {
              viewItem: "View details for {itemName}",
            },
          },
          Common: {
            checkbox: "checkbox",
          },
        },
      }}
    >
      <Provider config={config}>
        <Page titleHidden={true}>
          <Stack vertical>
            <PageActions
              primaryAction={
                user.loggedIn
                  ? {
                      content: "Help",
                      url: `/help${window.location.search}`,
                    }
                  : undefined
              }
              secondaryActions={
                user.loggedIn
                  ? [
                      {
                        content: "New",
                        icon: MobilePlusMajor,
                        url: `/rule${window.location.search}`,
                      },
                      {
                        content: "Active",
                        icon: ListMajor,
                        url: `/scheduled${window.location.search}`,
                      },
                      {
                        content: "Log",
                        icon: ChecklistAlternateMajor,
                        url: `/log${window.location.search}`,
                      },
                      {
                        content: "Account",
                        icon: StoreMajor,
                        url: `/account${window.location.search}`,
                      },
                    ]
                  : []
              }
            />
            <Router>
              <Switch>
                <Route exact path="/rule/:ruleId?">
                  <RuleDetail {...{ user, authError, scheduledRulesObservable, current }} />
                </Route>
                <Route path="/scheduled/:page?">
                  <ScheduledRules {...{ searchScheduledRulesObservable, user, customerSearchResults, setCustomerSearchResults, targetingSearch, setTargetingSearch }} />
                </Route>
                <Route path="/log/:page?">
                  <ExecutedLog {...{ executedRulesObservable, user }} />
                </Route>
                <Route path="/account">
                  <Account {...{ current, scheduledRulesObservable }} />
                </Route>
                <Route path="/help">
                  <Help />
                </Route>
                <Route path="/privacy">
                  <Privacy />
                </Route>
                <Route path="/">
                  <Start {...{ requestLogin }} />
                </Route>
                <Route>
                  <Start {...{ requestLogin }} />
                </Route>
              </Switch>
            </Router>
          </Stack>
        </Page>
      </Provider>
    </AppProvider>
  );
}

export default App;
