export interface Feature {
  name: string;
  key: string;
}

export interface Plan {
  name: string;
  key: string;
  price: number;
  description: string;
  features: Feature[];
  additional?: string;
  quota: {
    total: number,
    general: number,
    specific: number,
    additional: string
  }
}

export const plans: Plan[] = [
  {
    name: "Free",
    key: "free",
    description: "Our free plan",
    features: [
      {
        key: "free-general",
        name: "2 active rules",
      },
      {
        key: "free-specific",
        name: "20 triggers per month",
      },
    ],
    price: 0,
    quota: {
      total: 32,
      general: 1,
      specific: 16,
      additional: "N/A"
    }
  },
  {
    name: "The Groove",
    key: "groove",
    description: "The plan to get you in the groove",
    features: [
      {
        key: "groove-general",
        name: "Up to 10 active rules",
      },
      {
        key: "groove-specific",
        name: "200 triggers per month",
      },
    ],
    price: 1600,
    quota: {
      total: -1,
      general: 5,
      specific: 16,
      additional: "$0.20"
    }
  },
  {
    name: "The Goods",
    key: "goods",
    description: "This plan has the goods",
    features: [
      {
        key: "goods-general",
        name: "Up to 100 active rules",
      },
      {
        key: "goods-specific",
        name: "2,000 triggers per month",
      },
    ],
    price: 2400,
    quota: {
      total: -1,
      general: 10,
      specific: 128,
      additional: "$0.15"
    }
  },
  {
    name: "The Greats",
    key: "greats",
    description: "This plan has it over the goods, its great",
    features: [
      {
        key: "greats-general",
        name: "Up to 500 active rules",
      },
      {
        key: "goods-specific",
        name: "20,000 triggers per month",
      },
    ],
    price: 3600,
    quota: {
      total: -1,
      general: -1,
      specific: 256,
      additional: "$0.12"
    }
  },
  {
    name: "The Nuts",
    key: "nuts",
    description: "This plan is as good as it gets",
    features: [
      {
        key: "nuts-general",
        name: "Unlimited active rules",
      },
      {
        key: "nuts-specific",
        name: "100,000 triggers per month",
      },
    ],
    additional: "Custom pricing available for larger volumes",
    price: 7200,
    quota: {
      total: -1,
      general: -1,
      specific: 512,
      additional: "$0.10"
    }
  },
];
