import { WhoTargeting, RuleSubjectsValues, RuleActionsValues } from "../components/Rule/Who/WhoTypes";
import { WhatTypes, WhatOperations, WhatConstants } from "../components/Rule/What/WhatTypes";
import firebase from "firebase/app";

const generalTargetingRuleLabel = (rule) => {
  switch (rule.subject) {
    case RuleSubjectsValues.ORDER_TAGS: {
      const subject = RuleSubjectsValues.ORDER_LINE_TEXT;
      if (rule.action === RuleActionsValues.DOES) {
        return `${subject} ${RuleSubjectsValues.TAGGED_WITH} "${rule.value}"`;
      } else {
        return `${subject} ${RuleSubjectsValues.NOT_TAGGED_WITH} "${rule.value}"`;
      }
    }
    case RuleSubjectsValues.CUSTOMER_TAGS: {
      const subject = RuleSubjectsValues.CUSTOMER_TEXT;
      if (rule.action === RuleActionsValues.DOES) {
        return `${subject} ${RuleSubjectsValues.TAGGED_WITH} "${rule.value}"`;
      } else {
        return `${subject} ${RuleSubjectsValues.NOT_TAGGED_WITH} "${rule.value}"`;
      }
    }
    case RuleSubjectsValues.ORDER_LINE_ITEM: {
      const subject = RuleSubjectsValues.ORDER_LINE_TEXT;
      switch (rule.action) {
        case RuleActionsValues.CONTAINS_SKU:
          return `${subject} ${RuleSubjectsValues.CONTAINS_SKU} "${rule.value}"`;
        case RuleActionsValues.ONLY_CONTAINS_SKU:
          return `${subject} ${RuleSubjectsValues.ONLY_CONTAINS_SKU} "${rule.value}"`;
        case RuleActionsValues.NOT_CONTAINS_SKU:
          return `${subject} ${RuleSubjectsValues.NOT_CONTAINS_SKU} "${rule.value}"`;
        default:
          return ``;
      }
    }
    case RuleSubjectsValues.SHIPPING_ADDRESS: {
      const subject = RuleSubjectsValues.SHIPPING_ADDRESS_LABEL;
      return `${subject} ${rule.action} "${rule.value}"`;
    }
    case RuleSubjectsValues.BILLING_ADDRESS: {
      const subject = RuleSubjectsValues.BILLING_ADDRESS_LABEL;
      return `${subject} ${rule.action} "${rule.value}"`;
    }
    case RuleSubjectsValues.ORDER_NUMBER: {
      const subject = RuleSubjectsValues.ORDER_NUMBER_LABEL;
      let details = (rule.action === "multiple" ? "of" : "to") + " \""+ rule.value +"\"";
      if(rule.action === 'first') {
        details = "";
      }
      return `${subject} is ${rule.action} ${details}`;
    }
    default:
      return RuleSubjectsValues.N_A;
  }
};

const productTitle = (product) => {
  return product !== null ? (product.sku !== "" ? `${product.name}(${product.sku})` : `${product.name}`) : "";
};

const whatActionRuleLabel = (rule) => {
  switch (rule.action) {
    case WhatTypes.TAG_ORDER_VALUE:
      return `Will have the customer tag "${rule.config.tag}" applied`;
    case WhatTypes.DISCOUNT_VALUE:
      return `Not enabled yet`;
    case WhatTypes.EMAIL_VALUE:
      return `Not enabled yet`;
    case WhatTypes.PRODUCT_VALUE:
      const triggerProduct = productTitle(rule.config.triggerProduct);
      const targetProduct = productTitle(rule.config.targetProduct);

      const initialText = `${WhatConstants.SELECT_PRODUCT_LABEL} "${triggerProduct}"`;

      const operation = getWhatOperationText(
        rule.config.operation,
        targetProduct,
        rule.config.quantityOperation,
        rule.config.quantityValue
      );
      const priceOperation = getWhatPriceChangeText(rule.config.priceOperation);
      return `${rule.config.operation !== WhatOperations.ADD_PRODUCT ? initialText : ""} ${operation} ${priceOperation}`;

    default:
      return WhatTypes.N_A;
  }
};

const getWhatOperationText = (operation: string, targetProduct: string, quantityOperation: string, quantityValue: string): string => {
  switch (operation) {
    case WhatOperations.ADD_PRODUCT:
      return `${WhatOperations.ADD_PRODUCT_TEXT} "${targetProduct}" and ${getWhatQtyText(quantityOperation, quantityValue)}`;
    case WhatOperations.REPLACE_PRODUCT:
      return `${WhatOperations.REPLACE_PRODUCT_TEXT} "${targetProduct}" and ${getWhatQtyText(quantityOperation, quantityValue)}`;
    case WhatOperations.MODIFY_QTY_PRODUCT:
      return `${WhatOperations.MODIFY_QTY_PRODUCT_TEXT} and ${getWhatQtyText(quantityOperation, quantityValue)}`;
    case WhatOperations.REMOVE_PRODUCT:
      return `${WhatOperations.REMOVE_PRODUCT_TEXT}`;
    default:
      return "";
  }
};

const getWhatQtyText = (quantityOperation: string, quantityValue: string) => {
  switch (quantityOperation) {
    case WhatOperations.MATCH_INIT_QTY:
      return `${WhatOperations.MATCH_INIT_QTY_TEXT}`;
    case WhatOperations.FIXED_QTY:
      return `${WhatOperations.FIXED_QTY_TEXT} "${quantityValue}"`;
    case WhatOperations.CALCULATE_QTY:
      return `${WhatOperations.CALCULATE_QTY_TEXT} "${quantityValue}"`;
    default:
      return "";
  }
};

const getWhatPriceChangeText = (priceOperation: string): string => {
  let priceText = WhatConstants.PRICE_LABEL;
  switch (priceOperation) {
    case WhatOperations.IGNORE_PRICE_DIFFERENCE:
      return `${priceText} ${WhatOperations.IGNORE_PRICE_DIFFERENCE_TEXT}`;
    case WhatOperations.SEND_INVOICE:
      return `${priceText} ${WhatOperations.SEND_INVOICE_TEXT}`;
    case WhatOperations.PENDING:
      return `${priceText} ${WhatOperations.PENDING_TEXT}`;
    default:
      return "";
  }
};

const whenAvailabilityRuleLabel = (rule) => {
  switch (rule.availability) {
    case "once":
      return "The next order";
    case "fixed":
      return `Next ${rule.config.availabilityFixed} orders`;
    case "unlimited":
      return `Any orders`;
  }
  return "N/A";
};

const whenLimitRuleLabel = (rule) => {
  switch (rule.limit) {
    case "customer":
      return "for each targeted customer";
    case "total":
      return `in total`;
    case "single":
      return `placed`;
  }
  return "N/A";
};

const optInUrlLabel = (rule) => {
  return `https://${firebase.auth().currentUser?.uid}/apps/${process.env.REACT_APP_OPT_IN_PROXY}/${rule.id}/{{ ${
    rule.who.config.type === "order" ? "order.id" : rule.who.config.emailLookup === true ? "email" : "customer.id"
  } }}`;
};

const buildScheduledRuleRenderItem = (rule) => {
  if (rule.config == null) rule.config = {};
  try {
    return {
      id: rule.id,
      label: rule.label,
      targeting: rule.who.targeting,
      who:
        rule.who.targeting === WhoTargeting.SPECIFIC_VALUE
          ? rule.who.config.customers.map((customer) => `${customer.firstName} ${customer.lastName}`).join(" and ")
          : rule.who.targeting === WhoTargeting.OPT_IN_VALUE
          ? `All customers who click the link: ${optInUrlLabel(rule)}`
          : "All customers - " +
            (rule.who.config.targetingRules || [])
              .map((andRule) =>
                Object.values(andRule)
                  .map((orRule: any) => generalTargetingRuleLabel(orRule))
                  .join(" or ")
              )
              .join(" and "),
      what: whatActionRuleLabel(rule.what),
      when: `${whenAvailabilityRuleLabel(rule.when)} ${whenLimitRuleLabel(
        rule.when.availability === "once" ? { limit: "single" } : rule.when
      )}`,
    };
  } catch (error) {
    return {
      id: "err", 
      label: "n/a",
      targeting: "n/a",
      who: "n/a",
      what: "n/a",
      when: "n/a",
    };
  }
};

const getFirstName = (rule) => {
  if (rule.customer && rule.customer.default_address && rule.customer.default_address.first_name) {
    return rule.customer.default_address.first_name;
  } else if (rule.first_name) {
    return rule.first_name;
  } else if (rule.email) {
    return rule.email;
  } else {
    return "";
  }
};

const getLastName = (rule) => {
  if (rule.customer && rule.customer.default_address && rule.customer.default_address.last_name) {
    return rule.customer.default_address.last_name;
  } else if (rule.last_name) {
    return rule.last_name;
  } else if (rule.email) {
    return rule.email;
  } else {
    return "";
  }
};

const buildExecutedRuleRenderItem = (rule, index) => {
  return {
    id: `${rule.ruleId}-${index}`,
    ruleId: rule.ruleId,
    rule: rule.rule,
    date: rule.date.toDate(),
    customerId: rule.customerId,
    firstName: getFirstName(rule),
    lastName: getLastName(rule),
    entityId: rule.entityId,
    what: whatActionRuleLabel(rule.what),
  };
};

export { optInUrlLabel, buildScheduledRuleRenderItem, buildExecutedRuleRenderItem };
