import React, { useState, useEffect, useCallback } from "react";
import { Stack, Select, TextField, Checkbox, TextStyle } from "@shopify/polaris";
import { ProductSearchField } from "../../ProductSearchField";
import { WhatConstants, WhatOperations } from "./WhatTypes";

function ProductActionForm({ setConfig, initial }) {
  const [triggerProduct, setTriggerProduct] = useState(null);
  const [targetProduct, setTargetProduct] = useState(null);
  const [operation, setOperation] = useState("add");
  const [quantityOperation, setQuantityOperation] = useState("fixed");
  const [quantityValue, setQuantityValue] = useState("");
  const [priceOperation, setPriceOperation] = useState("ignore");
  const [quantityOperator, setQuantityOperator] = useState("add");
  const [notifyCustomer, setNotifyCustomer] = useState(false);

  const handleOperationChange = useCallback((value) => {
    setOperation(value);
    handleQuantityValueChange(0);
    switch (value) {
      case WhatOperations.ADD_PRODUCT:
        handleQuantityOperationChange(WhatOperations.FIXED_QTY);
        setTriggerProduct(null);
        break;
      case WhatOperations.MODIFY_QTY_PRODUCT:
        handleQuantityOperationChange(WhatOperations.FIXED_QTY);
        setTargetProduct(null);
        break;
      case WhatOperations.REMOVE_PRODUCT:
        setTargetProduct(null);
        break;
      case WhatOperations.REPLACE_PRODUCT:
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, []);
  const handleQuantityOperationChange = useCallback((value) => {
    setQuantityOperation(value);
    handleQuantityValueChange(0);
    // eslint-disable-next-line
  }, []);
  const handleQuantityValueChange = useCallback((value) => setQuantityValue(value), []);
  const handlePriceOperationChange = useCallback((value) => setPriceOperation(value), []);
  const handleQuantityOperatorChange = useCallback((value) => {
    if (value !== undefined) setQuantityOperator(value);
    handleQuantityValueChange(0);
    // eslint-disable-next-line
  }, []);
  const handleNotifyCustomerChange = useCallback((value) => {
    if (value !== undefined) setNotifyCustomer(value);
  }, []);

  useEffect(
    () => {
      if (initial && initial.what.config) {
        handleOperationChange(initial.what.config.operation);
        handleQuantityOperationChange(initial.what.config.quantityOperation);
        handlePriceOperationChange(initial.what.config.priceOperation);
        handleQuantityOperatorChange(initial.what.config.quantityOperator);
        handleNotifyCustomerChange(initial.what.config.notifyCustomer);
        setTriggerProduct(initial.what.config.triggerProduct);
        setTargetProduct(initial.what.config.targetProduct);
        handleQuantityValueChange(initial.what.config.quantityValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initial]
  );

  useEffect(
    () => {
      setConfig({
        operation,
        triggerProduct,
        targetProduct,
        quantityOperation,
        quantityOperator,
        quantityValue,
        priceOperation,
        notifyCustomer,
      });
    },
    // eslint-disable-next-line
    [
      handleOperationChange,
      operation,
      triggerProduct,
      targetProduct,
      quantityOperation,
      quantityOperator,
      quantityValue,
      priceOperation,
      notifyCustomer,
      handleQuantityOperationChange,
      handleQuantityValueChange,
      handlePriceOperationChange,
      handleQuantityOperatorChange,
      handleNotifyCustomerChange,
    ]
  );

  const targetLabel = `${WhatOperations.PRODUCT_TO} ${
    operation === WhatOperations.REPLACE_PRODUCT ? WhatOperations.REPLACE_PRODUCT_TEXT : WhatOperations.ADD_PRODUCT_TEXT
  }`;

  return (
    <Stack vertical>
      <TextStyle variation="negative">Modifying the contents of an order can have a financial impacts on the business or customers. Check rule configuration carefully.</TextStyle>
      <Select label="" labelHidden options={WhatConstants.OPERATIONS} value={operation} onChange={handleOperationChange} />

      {operation !== WhatOperations.ADD_PRODUCT ? (
        <ProductSearchField
          {...{
            selectedLabel: WhatConstants.SELECT_PRODUCT_LABEL,
            searchLabel: WhatConstants.SEARCH_PRODUCT_TRIGGER_LABEL,
            product: triggerProduct,
            selectProduct: (product) => {
              setTriggerProduct(product);
            },
          }}
        />
      ) : null}

      {[WhatOperations.REPLACE_PRODUCT, WhatOperations.ADD_PRODUCT].includes(operation) ? (
        <ProductSearchField
          {...{
            selectedLabel: targetLabel,
            searchLabel: WhatConstants.SEARCH_PRODUCT_TARGET_LABEL(operation),
            product: targetProduct,
            selectProduct: (product) => {
              setTargetProduct(product);
            },
          }}
        />
      ) : null}
      {operation !== WhatOperations.REMOVE_PRODUCT ? (
        <Select
          label={WhatConstants.QUANTITY_OPERATIONS_LABEL}
          options={WhatConstants.QUANTITY_OPERATIONS(operation)}
          value={quantityOperation}
          onChange={handleQuantityOperationChange}
        />
      ) : (
        ""
      )}
      {quantityOperation === WhatOperations.CALCULATE_QTY ? (
        <Select
          label={WhatConstants.CALCULATE_QTY_LABEL}
          options={WhatConstants.CALCULATE_QTY}
          value={quantityOperator}
          onChange={handleQuantityOperatorChange}
        />
      ) : null}
      {quantityOperation !== WhatOperations.MATCH_INIT_QTY && operation !== WhatOperations.REMOVE_PRODUCT ? (
        <TextField autoComplete="off" label={WhatConstants.QUANTITY_OPERATIONS_SET_QTY_AMOUNT} value={quantityValue} onChange={handleQuantityValueChange} />
      ) : null}
      <Select
        label={WhatConstants.PRICE_LABEL}
        options={WhatConstants.PRICE_OPERATIONS}
        value={priceOperation}
        onChange={handlePriceOperationChange}
      />
      {priceOperation === WhatOperations.SEND_INVOICE ? (
        <Checkbox label={WhatConstants.NOTIFY_CUSTOMER_LABEL} checked={notifyCustomer} onChange={handleNotifyCustomerChange} />
      ) : null}
    </Stack>
  );
}

export { ProductActionForm };
