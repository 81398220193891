export const WhenConstants = {
  AVAILABILITIES: [
    { label: "Once", value: "once" },
    { label: "Fixed number", value: "fixed" },
    { label: "Unlimited", value: "unlimited" },
  ],
  LIMITS: [
    { label: "In total", value: "total" },
    { label: "Per customer", value: "customer" },
    // { label: "Per day", value: "per_day" },
    // { label: "Per month", value: "per_month" },
    // { label: "Per customer per day", value: "unlimited" },
    // { label: "Per customer per month", value: "unlimited" },
  ],
};
