import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { Rule } from "../model/Rule";
import { startDev } from "./api";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

firebase.initializeApp(firebaseConfig);
const firestore = () => firebase.firestore();

export const getShop = () => {
  if (firebase.auth().currentUser == null) window.location.replace("/");
  return (firebase.auth().currentUser || { uid: "" }).uid;
};

export const authenticate = async () => {
  // If the check takes > 1 second, supported will be false and timedOut will be true
  if (firebase.auth().currentUser != null) return;
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let token = params.get("at");
  if (window.location.hostname === "localhost") {
    const devStart = await startDev();
    token = devStart;
  }
  if (token == null) {
    const error = new Error("Login token not valid");
    error["code"] = "login-token-not-valid";
    throw error;
  }
  const result = await firebase.auth().signInWithCustomToken(token);
  // Hacky check to see if third party cookies are being rejected (Chrome incognito mode)
  try {
    window.localStorage.setItem("syncStore", JSON.stringify(firebase.auth().currentUser));
    JSON.parse(window.localStorage.getItem("syncStore") || "");
  } catch (err) {
    const error = new Error("Cookies not enabled");
    error["code"] = "cookies-not-enabled";
    throw error;
  }
  // const { supported, timedOut } = await cookieCheck();

  return result;
};

export const disconnect = async () => {
  await firebase.auth().signOut();
};

export const onAuthStateChange = (callback) => {
  return firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      if (window["analytics"] != null) {
        window["analytics"].identify(user.uid);
      }
      callback({ loggedIn: true, email: user.email, uid: user.uid });
    } else {
      callback({ loggedIn: false });
    }
  });
};

export const getRulesExceeded = (scheduledRulesObservable, current, ruleId) => {
  if (scheduledRulesObservable && current && !ruleId) {
    const rules = scheduledRulesObservable.docs.map((doc) => doc.data());
    const ruleCount =
      (rules.filter((rule) => rule.who.targeting === "general") || []).length +
      (rules.filter((rule) => rule.who.targeting === "optin") || []).length;
    if (current.plan?.quota?.general < 0) return false;
    if (ruleCount >= current.plan?.quota?.general) return true;
  }
  return false;
};

export const getScheduledRulesObserver = (observer) => {
  return firestore().collection("data").doc(getShop()).collection("scheduled").orderBy("updated", "desc").onSnapshot(observer);
};

export const searchScheduledRulesObserver = (observer, { customers, targeting }) => {
  if (customers && customers.length && targeting) {
    return firestore()
      .collection("data")
      .doc(getShop())
      .collection("scheduled")
      .where("who.config.customers", "array-contains-any", customers)
      .where("who.targeting", "==", targeting)
      .orderBy("updated", "desc")
      .limit(20)
      .onSnapshot(observer);
  } else if (customers && customers.length && !targeting) {
    return firestore()
      .collection("data")
      .doc(getShop())
      .collection("scheduled")
      .where("who.config.customers", "array-contains-any", customers)
      .orderBy("updated", "desc")
      .limit(20)
      .onSnapshot(observer);
  } else if (!customers && targeting) {
    return firestore()
      .collection("data")
      .doc(getShop())
      .collection("scheduled")
      .where("who.targeting", "==", targeting)
      .orderBy("updated", "desc")
      .limit(20)
      .onSnapshot(observer);
  } else if (customers && !customers.length && !targeting) {
    return new Promise((resolve) => resolve(null));
  } else if (!customers && !targeting) {
    return firestore().collection("data").doc(getShop()).collection("scheduled").orderBy("updated", "desc").limit(20).onSnapshot(observer);
  }
};

export const getNextScheduledRule = (start) => {
  return firestore().collection("data").doc(getShop()).collection("scheduled").orderBy("updated", "desc").startAfter(start).limit(20).get();
};

export const getPreviousScheduledRule = (end) => {
  return firestore()
    .collection("data")
    .doc(getShop())
    .collection("scheduled")
    .orderBy("updated", "desc")
    .endBefore(end)
    .limitToLast(20)
    .get();
};

export const createScheduledRule = async (rule: Rule) => {
  return firestore().collection("data").doc(getShop()).collection("scheduled").doc(rule.id).set(rule);
};

export const deleteScheduledRule = async (ruleId: string) => {
  return firestore().collection("data").doc(getShop()).collection("scheduled").doc(ruleId).delete();
};

export const getScheduledRule = (ruleId: string) => {
  return firestore().collection("data").doc(getShop()).collection("scheduled").doc(ruleId).get();
};

export const getCountRules = () => {
  let count: number = 0;
  firestore()
    .collection("data")
    .doc(getShop())
    .collection("log")
    .get()
    .then((snapshot) => (count = snapshot.size));
  return count;
};

export const getLogRulesObserver = (observer) => {
  return firestore().collection("data").doc(getShop()).collection("log").orderBy("date", "desc").limit(20).onSnapshot(observer);
};

export const getLogRules = async () => {
  return firestore().collection("data").doc(getShop()).collection("log").orderBy("date", "desc").limit(20).get();
};

export const getNextLog = (start) => {
  return firestore().collection("data").doc(getShop()).collection("log").orderBy("date", "desc").startAfter(start).limit(20).get();
};

export const getPreviousLog = (end) => {
  return firestore().collection("data").doc(getShop()).collection("log").orderBy("date", "desc").endBefore(end).limitToLast(20).get();
};
