import axios from "axios";
// import Cookies from "js-cookie";
// import queryString from "query-string";

const apiUrl = process.env.REACT_APP_FUNCTIONS_API;
const appApiUrl = process.env.REACT_APP_FUNCTIONS_SHOPIFY_API;

const searchToObject = (search) => {
  return search.substring(1).split("&").reduce(function(result, value) {
    var parts = value.split('=');
    if (parts[0]) result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
    return result;
  }, {})
}

export const getShopOrigin = (): string => {
  if (window.location.hostname === "localhost") {
    return `${process.env.REACT_APP_TEST_SHOP}`;
  }
  return `${searchToObject(window.location.search).shop}`;
};

export const startDev = async () => {
  const result = await axios.get(`${appApiUrl}/app/start?shop=${process.env.REACT_APP_TEST_SHOP}`);
  return result.data;
};

export const getLoginToken = async (): Promise<string> => {
  const result = await axios.get(`${apiUrl}/account/login`);
  return result.data;
};

export const searchCustomers = async (input) => {
  const result = await axios.post(`${apiUrl}/shop/customers/search${window.location.search}`, { query: input });
  return result.data.results;
};

export const searchProducts = async (input) => {
  const result = await axios.post(`${apiUrl}/shop/products/search${window.location.search}`, { query: input });
  let variants = [];

  for (const product of result.data.results) {
    if (product && product.variants.length) {
      variants = variants.concat(
        product.variants.map((variant) => {
          return {
            id: variant.id,
            image: product.image,
            name: product.hasOnlyDefaultVariant ? product.name : `${product.name} ${variant.title}`,
            sku: variant.sku,
          };
        })
      );
    }
  }

  return variants;
};

export const getPlanAndUsage = async () => {
  const result = await axios.get(`${appApiUrl}/subscription/get${window.location.search}`);
  return result.data;
};

export const setPlan = async (key) => {
  const result = await axios.get(`${appApiUrl}/subscription/subscribe${window.location.search}&key=${key}`);
  return result.data;
};
