export const WhatTypes = {
  TAG_ORDER_LABEL: "Apply a Tag",
  TAG_ORDER_VALUE: "tag",
  DISCOUNT_LABEL: "Apply a discount (coming soon)",
  DISCOUNT_VALUE: "discount",
  EMAIL_LABEL: "Send an email (coming soon)",
  EMAIL_VALUE: "email",
  PRODUCT_LABEL: "Add, Remove or Update a line item",
  PRODUCT_VALUE: "product",
  FLOW_TRIGGER_LABEL: "Pass trigger to Shopify Flow™",
  FLOW_TRIGGER_VALUE: "flow",
  N_A: "N/A",
};

export const WhatOperations = {
  PRODUCT_TO: "Product to",
  ADD_PRODUCT: "add",
  ADD_PRODUCT_LABEL: "Add a new product to the order",
  ADD_PRODUCT_TEXT: "add",
  REPLACE_PRODUCT_LABEL: "Replace with another product",
  REPLACE_PRODUCT: "replace",
  REPLACE_PRODUCT_TEXT: "Replace with",
  MODIFY_QTY_PRODUCT: "quantity",
  MODIFY_QTY_PRODUCT_LABEL: "Modify the quantity",
  MODIFY_QTY_PRODUCT_TEXT: "Modify its quantity",
  REMOVE_PRODUCT: "remove",
  REMOVE_PRODUCT_LABEL: "Remove the product",
  REMOVE_PRODUCT_TEXT: "remove it",
  MATCH_INIT_QTY: "match",
  MATCH_INIT_QTY_LABEL: "Match the initial quantity",
  MATCH_INIT_QTY_TEXT: "match the initial quantity",
  FIXED_QTY: "fixed",
  FIXED_QTY_LABEL: "Fixed quantity",
  FIXED_QTY_TEXT: "fix quantity to",
  CALCULATE_QTY: "calculate",
  CALCULATE_QTY_LABEL: "Calculate the quantity",
  CALCULATE_QTY_TEXT: "calculate its quantity as",
  IGNORE_PRICE_DIFFERENCE: "ignore",
  IGNORE_PRICE_DIFFERENCE_LABEL: "Ignore price differences",
  IGNORE_PRICE_DIFFERENCE_TEXT: "ignore price differences",
  SEND_INVOICE: "invoice",
  SEND_INVOICE_LABEL: "Send refund or invoice notification",
  SEND_INVOICE_TEXT: "send refund or invoice notification",
  PENDING: "pending",
  PENDING_LABEL: "Place order in partially paid status",
  PENDING_TEXT: "place order in partially paid status",
};

export const WhatConstants = {
  EMAIL: "email",
  EMAIL_SEND_TO: "Send To",
  EMAIL_FROM: "From",
  EMAIL_TO: "To",
  EMAIL_SUBJECT: "Subject",
  EMAIL_BODY: "Body",
  ACTION_TITLE: "Select an action",
  ACTION_TYPES: [
    { label: WhatTypes.TAG_ORDER_LABEL, value: WhatTypes.TAG_ORDER_VALUE },
    { label: WhatTypes.PRODUCT_LABEL, value: WhatTypes.PRODUCT_VALUE },
    { label: WhatTypes.FLOW_TRIGGER_LABEL, value: WhatTypes.FLOW_TRIGGER_VALUE },
    // { label: WhatTypes.DISCOUNT_LABEL, value: WhatTypes.DISCOUNT_VALUE, disabled: true },
    // { label: WhatTypes.EMAIL_LABEL, value: WhatTypes.EMAIL_VALUE, disabled: true },
  ],
  SELECT_PRODUCT_LABEL: "If an order contains",
  SEARCH_PRODUCT_TRIGGER_LABEL: "Select product to look for on an order",
  SEARCH_PRODUCT_TARGET_LABEL: (operation) => `Select product to ${operation}`,
  OPERATIONS: [
    { label: WhatOperations.ADD_PRODUCT_LABEL, value: WhatOperations.ADD_PRODUCT },
    { label: WhatOperations.REPLACE_PRODUCT_LABEL, value: WhatOperations.REPLACE_PRODUCT },
    { label: WhatOperations.MODIFY_QTY_PRODUCT_LABEL, value: WhatOperations.MODIFY_QTY_PRODUCT },
    { label: WhatOperations.REMOVE_PRODUCT_LABEL, value: WhatOperations.REMOVE_PRODUCT },
  ],
  QUANTITY_OPERATIONS_LABEL: "How to do you want to manage the quantity",
  QUANTITY_OPERATIONS: (operation) => [
    {
      label: WhatOperations.MATCH_INIT_QTY_LABEL,
      value: WhatOperations.MATCH_INIT_QTY,
      disabled: [WhatOperations.MODIFY_QTY_PRODUCT, WhatOperations.ADD_PRODUCT].includes(operation) ? true : false,
    },
    { label: WhatOperations.FIXED_QTY_LABEL, value: WhatOperations.FIXED_QTY },
    {
      label: WhatOperations.CALCULATE_QTY_LABEL,
      value: WhatOperations.CALCULATE_QTY,
      disabled: [WhatOperations.ADD_PRODUCT].includes(operation) ? true : false,
    },
  ],
  CALCULATE_QTY_LABEL: "Operator",
  CALCULATE_QTY: [
    { label: "Add (+)", value: "add" },
    { label: "Subtract (-)", value: "subtract" },
    { label: "Divide (/)", value: "divide" },
    { label: "Multiple (*)", value: "multiple" },
  ],
  QUANTITY_OPERATIONS_SET_QTY_AMOUNT: "Set the quantity amount",
  QUANTITY_OPERATIONS_SET_QTY_CALCULATION: "Set the quantity calculation",
  QUANTITY_OPERATIONS_MULTIPLY_QTY: "to multiple quantity use 'q * 3'",
  PRICE_LABEL: "If the modifications result in a different order price",
  PRICE_OPERATIONS: [
    { label: WhatOperations.IGNORE_PRICE_DIFFERENCE_LABEL, value: WhatOperations.IGNORE_PRICE_DIFFERENCE },
    { label: WhatOperations.PENDING_LABEL, value: WhatOperations.PENDING, disabled: true },
    { label: WhatOperations.SEND_INVOICE_LABEL, value: WhatOperations.SEND_INVOICE },
  ],
  EMAIL_RECIPIENTS: [
    { label: "The Customer", value: "customer" },
    { label: "Me", value: "me" },
    { label: "Another address", value: "email" },
  ],
  DISCOUNT_TYPES_LABEL: "Discount Type",
  DISCOUNT_TYPES: [
    { label: "Fixed discount", value: "fixed" },
    { label: "Percentage discount", value: "percent" },
  ],
  DISCOUNT_AMOUNT_LABEL: "Amount",
  NOTIFY_CUSTOMER_LABEL: "Notify Customer",
  TAG_LABEL: "Tag to apply to order",
};
