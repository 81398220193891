import React, { useState, useEffect, useCallback } from "react";
import { CalloutCard, ChoiceList, Stack, TextField, Tooltip } from "@shopify/polaris";
import useCopyToClipboard from '../../../services/CopyToClipboard';

function FlowActionForm({ setConfig, initial = {} as any }) {
  const { isCopied, copyToClipboard } = useCopyToClipboard(3000);

  return (
    <Stack vertical>
      {initial.id ? <><CalloutCard
          title="Configure Shopify Flow trigger"
          illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
          primaryAction={{
            content: "Copy Rule ID to clipboard",
            onAction: () => copyToClipboard(initial.id),
          }}
        >
          <p>
            {isCopied ? (
              <Tooltip active content="Copied!">
                Copied
              </Tooltip>
            ) : (
              <p>
                Copy the rule ID and configure the Shopify Flow as displayed in the image below.
              <a target="_blank" rel="noopener noreferrer" href={initial.id}>
                {initial.id}
              </a>
              </p>
            )}
          </p>
        </CalloutCard>
              <div><img style={{"width":"100%", "marginTop": "10px"}} src='/assets/shopify-flow-rule-config.png' alt="Shopify Flow config" /></div></> : <p>Instructions on how to configure Shopify Flow will be displayed once rule is saved.</p>}
    </Stack>
  );
}
export default FlowActionForm;
