function getAvailabilityDate(value: Date): Date | null;
function getAvailabilityDate(value: String): Date | null;
function getAvailabilityDate(value: any): Date | null {
    let date: Date | null = null
    if (value !== null && value !== undefined) {
        if (typeof value === "object")
            return date = value.toDate()
        else
            return date = new Date(parseInt(value.substr(0, 4)), parseInt(value.substr(5, 2)) - 1, parseInt(value.substr(8, 2)))
    }
    return date
}

function convertDateToString(date: Date): string {
    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + (date.getDate())).slice(-2)}`
}

function clearAvailabilityDatesFlag(value: boolean | null | undefined): boolean {
    if (value !== null && value !== undefined) return value;
    return false
}

export { getAvailabilityDate, convertDateToString, clearAvailabilityDatesFlag }
