import React, { useState, useEffect, useCallback } from "react";
import { Stack } from "@shopify/polaris";
import { CustomerSearchField } from "../../CustomerSearchField";

function SpecificTargetingForm({ config, setConfig }) {
  const [customers, setCustomers] = useState<any[]>([]);

  const setConfigCallback = useCallback(() => setConfig({ customers, targeted: (customers || []).map(c => c.id) }), [customers, setConfig]);
  const setCustomersCallback = useCallback(() => setCustomers(config.customers), [config]);

  useEffect(() => {
    if (config.customers) {
      setCustomersCallback();
    }
  }, [config, setCustomersCallback]);

  useEffect(() => {
    setConfigCallback();
  }, [customers, setConfigCallback]);

  return (
    <Stack spacing="tight" vertical>
      <CustomerSearchField
        {...{
          selectedLabel: "Targeted customers",
          searchLabel: "Search the customers to target by email, or Customer ID:",
          customers,
          selectCustomer: (customer) => setCustomers([...customers, customer]),
          removeCustomer: (customerId) => setCustomers(customers.filter((x) => x.id !== customerId)),
        }}
      />
    </Stack>
  );
}
export { SpecificTargetingForm };
