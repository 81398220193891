import React, { useState, useEffect, useCallback } from "react";
import { Stack, Card, Button, Label } from "@shopify/polaris";
import TargetingRuleForm from "./TargetingRuleForm";
import { TargetingRuleConstants } from "./WhoTypes";

function GeneralTargetingForm({ config, setConfig }) {
  const [targetingRules, setTargetingRules] = useState<Array<Object>>(config?.targetingRules || [{ 0: TargetingRuleConstants.INITIAL_RULE }]);
  const setTargetingRulesCallback = useCallback(() => setTargetingRules(config.targetingRules), [config]);
  const setConfigCallback = useCallback(() => setConfig({ targetingRules }), [targetingRules, setConfig]);

  useEffect(
    () => {
      if (config.targetingRules) {
        setTargetingRulesCallback();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setConfigCallback();
  }, [targetingRules, setConfigCallback]);

  const addOr = useCallback(
    (ix: any) => {
      const targetingRulesCopy = [...targetingRules];
      const item = targetingRulesCopy[ix];
      let nextKey = 0;
      if (item.hasOwnProperty(0)) {
        Object.keys(item).forEach((key, index) => {
          nextKey = index;
        });
        nextKey++;
      }
      item[nextKey] = TargetingRuleConstants.INITIAL_RULE;
      targetingRulesCopy[ix] = item;
      setTargetingRules(targetingRulesCopy);
    },
    [targetingRules]
  );

  const removeAnd = useCallback(
    (ix: any) => {
      const targetingRulesCopy = [...targetingRules];
      targetingRulesCopy.splice(ix, 1);
      setTargetingRules(targetingRulesCopy);
    },
    [targetingRules]
  );

  const addAnd = useCallback(() => {
    setTargetingRules([...targetingRules, { 0: TargetingRuleConstants.INITIAL_RULE }]);
  }, [targetingRules]);

  const updateRule = useCallback((andIx, orIx, rule) => {
      targetingRules[andIx][orIx] = rule;
      setTargetingRules(targetingRules);
    },
    [targetingRules]
  );

  const removeOr = useCallback(
    (andIx, orIx) => {
      const targetingRulesCopy = [...targetingRules];
      delete targetingRules[andIx][orIx];
      const item = {};

      Object.keys(targetingRules[andIx]).forEach((key, index) => {
        item[index] = targetingRules[andIx][key];
      });
      targetingRulesCopy[andIx] = item;
      setTargetingRules(targetingRulesCopy);
    },
    [targetingRules]
  );

  return (
    <Stack spacing="tight" vertical>
      <Label id="targeting-rules">Configure targeting rules</Label>
      {targetingRules.map((andGroup, andIndex) => {
        return (
          <Card sectioned key={`rule-${andIndex}`}>
            <Stack spacing="tight" vertical>
              {Object.values(andGroup).map((rule, orIndex) => {
                return (
                  <Card sectioned key={`rule-${andIndex}-${orIndex}`}>
                    <Stack spacing="loose" vertical>
                      <TargetingRuleForm
                        key={`targetrule-${andIndex}-${orIndex}`}
                        {...{
                          rule,
                          setRule: (rule) => {
                            updateRule(andIndex, orIndex, rule);
                          },
                        }}
                      />
                      <Stack spacing="tight" distribution="trailing">
                        <Button id={`delOr-${andIndex}-${orIndex}`} plain destructive onClick={() => removeOr(andIndex, orIndex)}>
                          Remove
                        </Button>
                      </Stack>
                    </Stack>
                  </Card>
                );
              })}
              <Stack spacing="loose">
                <Stack.Item fill>
                  <Button size="slim" id="or" onClick={() => addOr(andIndex)}>
                    +OR
                  </Button>
                </Stack.Item>
                <Stack.Item>
                  <Button id="delAnd" size="slim" plain destructive onClick={() => removeAnd(andIndex)}>
                    Remove AND
                  </Button>
                </Stack.Item>
              </Stack>
            </Stack>
          </Card>
        );
      })}
      <Button size="slim" id="and" onClick={() => addAnd()}>
        +AND
      </Button>
    </Stack>
  );
}
export { GeneralTargetingForm };
