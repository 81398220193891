import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Layout, Stack, Button, Banner, Spinner, Card, Heading, TextField, Frame, Loading } from "@shopify/polaris";
import { useState } from "react";
import * as FirestoreService from "../services/firebase";
import { Rule, Who, What, When } from "../model/Rule";
import { WhoForm, WhatForm, WhenForm } from "../components/Rule";
import { v4 } from "uuid";
import ErrorMessage from "../components/ErrorMessage";
import RuleQuota from "../components/RuleQuota";
import { getRulesExceeded } from "../services/firebase";
const RuleDetail = (props) => {
  const { user, authError, scheduledRulesObservable, current } = props;

  const [rulesExceeded, setRulesExceeded] = useState(false);
  const history = useHistory();
  const { ruleId } = useParams();
  const [error, setError] = useState<string | null>(null);
  const [ruleLabel, setRuleLabel] = useState<string>("");
  const [who, setWho] = useState<Who>();
  const [what, setWhat] = useState<What>();
  const [when, setWhen] = useState<When>();
  const [initial, setInitial] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setIsSuccess] = useState(false);

  async function deleteScheduledRule() {
    await FirestoreService.deleteScheduledRule(ruleId)
      .then(() => {
        history.push(`/rule${window.location.search}`);
        history.go(0);
      })
      .catch((err) => {
        setError(err);
      });
  }

  async function createScheduledRule() {
    setError(null);
    const id = ruleId || v4();
    const rule: Rule = {
      id: id,
      label: ruleLabel,
      created: new Date(),
      updated: new Date(),
      active: true,
      who,
      what,
      when,
    };

    setIsLoading(true);
    FirestoreService.createScheduledRule(rule)
      .then(() => {
        history.push(`/rule/${id}${window.location.search}`); //`/scheduled${window.location.search}`);
        setIsSuccess(true);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (window.scrollY) {
      window.scrollTo(0, 0);
    }
    if (ruleId != null) {
      FirestoreService.getScheduledRule(ruleId).then((data: any) => {
        setInitial(data.data());
      });
    }
  }, [ruleId]);

  useEffect(() => {
    setRuleLabel(initial?.label || "");
  }, [initial]);

  useEffect(() => {
    setRulesExceeded(getRulesExceeded(scheduledRulesObservable, current, ruleId));
  }, [scheduledRulesObservable, current, ruleId]);

  return (
    <>
      {isLoading && (
        <Frame>
          <Loading />
        </Frame>
      )}
      <Stack>
        {!user || user.loggedIn === false || authError !== "" || error != null ? (
          authError != null || error != null ? (
            <ErrorMessage errorCode={authError || error}></ErrorMessage>
          ) : (
            <Spinner />
          )
        ) : (
          <Layout>
            {!ruleId && (
              <Layout.Section>
                <Card sectioned>
                  <Heading>Add a new rule</Heading>
                  <p>Create a new rule that will apply to your customers the next time they complete checkout.</p>
                  <p>
                    Getting set up is easy - first you select what will <strong>Trigger</strong> the rule, then what <strong>Action</strong>{" "}
                    the rule will take, then you select <strong>Limits</strong> to when the rule will be active.
                  </p>
                </Card>
              </Layout.Section>
            )}

            {!ruleId && (
              <Layout.Section>
                <RuleQuota {...{ rulesExceeded }} />
              </Layout.Section>
            )}

            {success && (
              <Layout.Section>
                <Banner
                  status="success"
                  onDismiss={() => {
                    setIsSuccess(false);
                  }}
                >
                  <div>Your rule has been saved.</div>
                </Banner>
              </Layout.Section>
            )}

            {(ruleId || error) && (
              <Layout.Section>
                <Banner title={error ? error : `You are editing Rule ${ruleId}`} status={error ? `critical` : `warning`} />
              </Layout.Section>
            )}

            <Layout.Section>
              <Layout.AnnotatedSection
                title="Rule Label"
                description={
                  <Stack>
                    <p>Name your Rule for easy reference when viewing the Active list and Logs.</p>
                  </Stack>
                }
              >
                <Card sectioned>
                  <TextField
                    autoComplete="off"
                    label="Rule Label"
                    value={ruleLabel}
                    onChange={(value) => {
                      setRuleLabel(value);
                    }}
                  ></TextField>
                </Card>
              </Layout.AnnotatedSection>
              <WhoForm {...{ who, setWho, ruleId, initial, rulesExceeded }} />
              <WhatForm {...{ what, setWhat, initial }} />
              <WhenForm {...{ when, setWhen, initial }} />
            </Layout.Section>
            <Layout.Section>
              <Stack>
                <Stack.Item fill>
                  <Button onClick={createScheduledRule} primary={true}>
                    {ruleId ? `Update` : `Add`} Rule
                  </Button>
                </Stack.Item>
                <Stack.Item>
                  {ruleId ? (
                    <Button destructive onClick={deleteScheduledRule}>
                      Delete
                    </Button>
                  ) : (
                    ""
                  )}
                </Stack.Item>
              </Stack>
            </Layout.Section>
          </Layout>
        )}
      </Stack>
    </>
  );
};

export default RuleDetail;
