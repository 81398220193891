import React, { useState, useCallback, useEffect } from "react";
import { Stack, TextField, Select } from "@shopify/polaris";
import { WhatConstants } from "./WhatTypes";

function EmailActionForm({ setConfig, initial }) {
  const [recipientType, setRecipientType] = useState("customer");
  const [emailAddress, setEmailAddress] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  const handleRecipientTypeChange = useCallback((value) => setRecipientType(value), [setRecipientType]);
  const handleEmailAddressChange = useCallback((value) => setEmailAddress(value), [setEmailAddress]);
  const handleSubjectChange = useCallback((value) => setSubject(value), [setSubject]);
  const handleBodyChange = useCallback((value) => setBody(value), [setBody]);

  useEffect(
    () => {
      if (initial && initial.what.config) {
        handleRecipientTypeChange(initial.what.config.recipientType);
        handleEmailAddressChange(initial.what.config.emailAddress);
        handleSubjectChange(initial.what.config.subject);
        handleBodyChange(initial.what.config.body);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [initial]
  );

  useEffect(() => {
    setConfig({
      recipientType,
      emailAddress,
      subject,
      body,
    });
  }, [setConfig, recipientType, emailAddress, subject, body]);

  const recipientTypes = WhatConstants.EMAIL_RECIPIENTS;

  return (
    <Stack vertical>
      <Select label={WhatConstants.EMAIL_SEND_TO} options={recipientTypes} value={recipientType} onChange={handleRecipientTypeChange} />
      {recipientType === WhatConstants.EMAIL ? (
        <TextField autoComplete="off" label={WhatConstants.EMAIL_SUBJECT} value={emailAddress} onChange={handleEmailAddressChange} />
      ) : (
        ""
      )}
      <TextField autoComplete="off" label={WhatConstants.EMAIL_TO} value={subject} onChange={handleSubjectChange}></TextField>
      <TextField autoComplete="off" label={WhatConstants.EMAIL_BODY} multiline value={body} onChange={handleBodyChange}></TextField>
    </Stack>
  );
}
export { EmailActionForm };
