import React, { useState, useEffect, useCallback } from "react";
import { Stack, TextField, Select } from "@shopify/polaris";
import { RuleSubjectsValues, TargetingRuleConstants } from "./WhoTypes";

const TargetingRuleForm = ({ rule, setRule }) => {
  const [subject, setSubject] = useState<string>();
  const [action, setAction] = useState<string>();
  const [value, setValue] = useState<string>();
  const [visible, setVisible] = useState<boolean>(true);

  const subjects = TargetingRuleConstants.RULE_SUBJECTS;

  const getActions = useCallback((param) => {
    switch (param) {
      case RuleSubjectsValues.ORDER_TAGS:
      case RuleSubjectsValues.CUSTOMER_TAGS:
        return TargetingRuleConstants.ACTION_CUSTOMER_TAGS;
      case RuleSubjectsValues.ORDER_LINE_ITEM:
        return TargetingRuleConstants.ACTION_ORDER_LINE_ITEMS;
      case RuleSubjectsValues.SHIPPING_ADDRESS:
      case RuleSubjectsValues.BILLING_ADDRESS:
        return TargetingRuleConstants.ACTION_LOCATION;
      case RuleSubjectsValues.ORDER_NUMBER:
        return TargetingRuleConstants.ACTION_ORDER_NUMBER;
      case RuleSubjectsValues.ORDER_VALUE:
        return TargetingRuleConstants.ACTION_ORDER_VALUE;
      default:
        return TargetingRuleConstants.NO_ACTION;
    }
  },[]);

  const handleSubjectChange = useCallback((value) => {
    setSubject(value);
    const actions = getActions(value);
    (actions[0].value === 'first') ? setVisible(false) : setVisible(true);
    setAction(actions[0].value);
  }, [setSubject, getActions]);
  const handleActionChange = useCallback((value) => {
    setAction(value);
    (value === 'first') ? setVisible(false) : setVisible(true);
  }, [setAction]);
  const handleValueChange = useCallback((value) => setValue(value), [setValue]);

  useEffect(() => {
    if (subject && action) setRule({ subject, action, value });
  }, [subject, action, value, setRule]);

  useEffect(() => {
    setSubject(rule.subject);
    setAction(rule.action);
    setValue(rule.value);
    (rule.action === 'first') ? setVisible(false) : setVisible(true);
  }, [rule]);

  return (
    <Stack vertical spacing="tight">
      <Stack>
        <Select label="Target" options={subjects} value={subject} onChange={handleSubjectChange} />
        <Select label="Condition" options={getActions(subject)} value={action} onChange={handleActionChange} />
      </Stack>

      { (visible ? <TextField autoComplete="off" label="Value" value={value} onChange={handleValueChange}></TextField> : "") }
    </Stack>
  );
};

export default TargetingRuleForm;
