export const triggerMap = {
  specific: "Specific targeting",
  general: "Conditional targeting",
  optin: "Opt-in"
}

export const ruleMap = {
  specific: "Show comma separated list of Customer Emails/IDs",
  general: "If Field Label value exists, show Field Label;<br />else<br />Show current content from 'Scheduled' list " + 
  "(eg All customers - Item tagged with \"Example\"; The next order place, Will have the customer tag \"Example\" applied)",
  optin: "If Field Label value exists, show Field Label;<br />else<br />Show current content from 'Scheduled' list " + 
  "(eg All customers - Item tagged with \"Example\"; The next order place, Will have the customer tag \"Example\" applied)"
}