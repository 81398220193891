import React, { useState, useEffect, useCallback } from "react";
import { Layout, Card, Stack, Select, Label, TextField, Checkbox, TextStyle } from "@shopify/polaris";
import { WhenConstants } from "./WhenTypes";
import { getAvailabilityDate, convertDateToString, clearAvailabilityDatesFlag } from "./WhenUtility";
import moment from "moment";

function WhenForm({ when, setWhen, initial }) {
  const [availability, setAvailability] = useState("once");
  const [availabilityFixed, setAvailabilityFixed] = useState("1");
  const [limit, setLimit] = useState("total");
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(new Date(new Date().getFullYear(), new Date().getMonth() + 6, new Date().getDate()));
  const [noStart, setNoStart] = useState(true);
  const [noExpiry, setNoExpiry] = useState(false);
  const [config, setConfig] = useState({});
  const [displayWarningMessage, setDisplayWarningMessage] = useState(false);

  const handleLimitChange = useCallback((value) => setLimit(value), []);
  const handleAvailabilityChange = useCallback((value) => setAvailability(value), []);
  const handleAvailabilityFixedChange = useCallback((value) => setAvailabilityFixed(value), []);
  const handleNoExpiryChange = useCallback((value) => setNoExpiry(clearAvailabilityDatesFlag(value)), []);
  const handleNoStartChange = useCallback((value) => setNoStart(clearAvailabilityDatesFlag(value)), []);
  const handleDateFromChange = useCallback((value) => setDateFrom(getAvailabilityDate(value)), []);
  const handleDateToChange = useCallback((value) => setDateTo(getAvailabilityDate(value)), []);

  useEffect(() => {
    if (limit === "customer" || availability === "unlimited" || noExpiry) {
      setDisplayWarningMessage(true);
    } else {
      setDisplayWarningMessage(false);
    }
  }, [limit, availability, noExpiry]);

  useEffect(
    () => {
      if (initial && initial.when) {
        handleAvailabilityChange(initial.when.availability);
        handleLimitChange(initial.when.limit);
        handleDateFromChange(initial.when.dateFrom);
        handleDateToChange(initial.when.dateTo);
        if (initial.when.dateTo == null) {
          handleNoExpiryChange(true);
          handleDateToChange(null);
        } else {
          handleNoExpiryChange(false);
        }
        if (initial.when.dateFrom == null) {
          handleNoStartChange(true);
          handleDateFromChange(null);
        } else {
          handleNoStartChange(false);
        }
        // handleNoExpiryChange(initial.when.availabilityDates);
        if (initial.when.config) handleAvailabilityFixedChange(initial.when.config.availabilityFixed);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initial]
  );

  useEffect(() => {
    noStart ? handleDateFromChange(null) : handleDateFromChange(moment().startOf("day"));
    // eslint-disable-next-line
  }, [noStart]);

  useEffect(() => {
    noExpiry
      ? handleDateToChange(null)
      : handleDateToChange(
          convertDateToString(
            new Date(
              dateFrom ? dateFrom.getFullYear() : moment().startOf("day").toDate().getFullYear(),
              dateFrom ? dateFrom.getMonth() + 6 : moment().startOf("day").toDate().getMonth() + 6,
              dateFrom ? dateFrom.getDate() : moment().startOf("day").toDate().getDate()
            )
          )
        );
    // eslint-disable-next-line
  }, [noExpiry]);

  useEffect(() => {
    setConfig({ availabilityFixed });
  }, [availabilityFixed, handleAvailabilityFixedChange]);

  useEffect(() => {
    setWhen({
      availability,
      noExpiry,
      noStart,
      dateFrom,
      dateTo,
      limit,
      config,
    });
  }, [
    config,
    availability,
    handleAvailabilityChange,
    limit,
    handleLimitChange,
    availabilityFixed,
    handleAvailabilityFixedChange,
    setWhen,
    noExpiry,
    noStart,
    dateTo,
    dateFrom,
  ]);

  return (
    <Layout.AnnotatedSection
      title="Limits"
      description={
        <Stack>
          <p>
            Choose whether you want to limit the rule to run a specified number of times, either in total or per customer, or a time period
            when the rule will be active.
            <br />
            <br />
            Remember that depending on your store and configuration, this rule could execute frequently.
          </p>
        </Stack>
      }
    >
      <Card sectioned>
        <Stack spacing="tight" vertical>
          <Label id="limit-to">Limit to</Label>
          <Stack>
            <Select label="" options={WhenConstants.AVAILABILITIES} value={availability} onChange={handleAvailabilityChange} />
            {availability === "fixed" ? (
              <TextField autoComplete="off" label="" suffix="times" type="number" value={availabilityFixed} onChange={handleAvailabilityFixedChange} />
            ) : (
              ""
            )}
            <Select label="" options={WhenConstants.LIMITS} value={limit} onChange={handleLimitChange} />
          </Stack>
          {/* <Checkbox label="Availability Dates" checked={availabilityDates} onChange={handleAvailabilityDatesChange} /> */}

          <Stack spacing="tight" vertical>
            <Stack spacing="tight">
              <TextField
                autoComplete="off"
                label="From"
                value={dateFrom != null ? convertDateToString(dateFrom) : ""}
                type="date"
                onChange={handleDateFromChange}
                helpText={<Checkbox label="Available from now" checked={noStart} onChange={handleNoStartChange} />}
              />
              <TextField
                autoComplete="off"
                label="Until"
                value={dateTo != null ? convertDateToString(dateTo) : ""}
                type="date"
                disabled={noExpiry}
                onChange={handleDateToChange}
                helpText={<Checkbox label="No expiry" checked={noExpiry} onChange={handleNoExpiryChange} />}
              />
            </Stack>
          </Stack>
          {displayWarningMessage && (
            <TextStyle variation="negative">This configuration may cause the rule to execute a large number of times.</TextStyle>
          )}
        </Stack>
      </Card>
    </Layout.AnnotatedSection>
  );
}
export default WhenForm;
